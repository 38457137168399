import { html } from 'lit';
import check from './check';
import { DEFAULT_TERMS } from '../../../helpers/constants';
import phonePartialOptInSuccess from './phonePartialOptInSuccess';
import arrow from './arrow';
import exitButton from './exitButton';
import phoneButtonBar from './phoneButtonBar';

const phonePartialOptIn = ({
  onSubmitOptIn,
  handleExit,
  headline,
  subHeadline,
  showSuccess,
  isLastCollectionScreen,
  terms = DEFAULT_TERMS(window.parentHostname),
  logoUrl,
  subscriberId,
  subscribeText,
  closeText,
}) => {
  if (showSuccess) return phonePartialOptInSuccess({ logoUrl, handleExit });

  return html`
    <div
      id="ps-mobile-widget-partial__container"
      aria-modal="true"
      role="dialog"
      aria-labelledby="ps-mobile-widget-partial__headline"
      aria-describedby="ps-mobile-widget-partial__sub-headline"
    >
      ${exitButton({
        id: 'ps-mobile-widget-partial__close-button',
        onClick: handleExit,
      })}
      <div id="ps-mobile-widget-partial__logo-container">
        ${logoUrl
          ? html`<img
              id="ps-mobile-widget-partial__logo-image"
              src="${logoUrl}"
            />`
          : null}
      </div>
      <div id="ps-mobile-widget-partial__header-container">
        <hgroup id="ps-mobile-widget-partial__header">
          <h2 id="ps-mobile-widget-partial__headline" class="ps-heading">
            ${headline}
          </h2>
          <h3 id="ps-mobile-widget-partial__sub-headline" class="ps-heading">
            ${subHeadline}
          </h3>
        </hgroup>
      </div>
      ${!subscriberId
        ? html`<div id="ps-mobile-widget-partial__terms">${terms}</div>`
        : null}
      <form
        @submit=${onSubmitOptIn}
        id="ps-mobile-widget-partial__form-wrapper"
        class="${subscriberId ? 'ps-subscribed' : ''}"
      >
        ${!subscriberId
          ? html`
              <div
                id="ps-mobile-widget-partial__phone-input-wrapper"
                style="width: 100%"
                data-popup-engagement="true"
              >
                <input
                  aria-label="Enter your phone number"
                  aria-describedby="ps-mobile-widget-partial__terms"
                  id="ps-mobile-widget-partial__phone-input"
                  data-cy="phone-input"
                />
              </div>
              <button
                type="submit"
                id="ps-mobile-widget-partial__phone-submit"
                data-cy="ps-mobile-widget-partial__phone-submit"
                data-popup-engagement="true"
                style="visibility:${subscriberId ? 'hidden' : 'visible'};"
                type="submit"
              >
                ${isLastCollectionScreen ? check() : arrow()}
              </button>
            `
          : phoneButtonBar({
              closeText,
              subscribeText,
              subscriberId,
              handleExit,
            })}
      </form>
    </div>
  `;
};

export default phonePartialOptIn;

import { html } from 'lit';

const submitButton = ({ text, isVisible = true }) => html`
  <button
    id="ps-desktop-widget__submit"
    data-cy="ps-desktop-widget__submit"
    type="submit"
    value="${text}"
    style="visibility: ${isVisible ? 'visible' : 'hidden'}"
    data-popup-engagement="true"
  >
    ${text}
  </button>
`;

export default submitButton;

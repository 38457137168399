export const QUESTION_OPT_IN_METHODS = {
  DOUBLE_OPT_IN: 'DOUBLE_OPT_IN',
  ONE_TIME_PASSCODE: 'ONE_TIME_PASSCODE',
  LINK_CLICK: 'LINK_CLICK',
  TWO_TOUCH: 'TWO_TOUCH',
} as const;

export const QUESTION_TYPES = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  RADIO: 'RADIO',
  MONTH_AND_DAY: 'MONTH_AND_DAY',
  TEXT: 'TEXT',
} as const;

export const CUSTOM_EVENTS = {
  VALIDATE_FORM: 'psValidateForm',
} as const;

export const ONE_TIME_PASSCODE_CLOSE_BUTTON_TEXT = 'Exit';
export const ONE_TIME_PASSCODE_HIDDEN_TEXT_ID =
  'ps-one-time-passcode-hidden-text';
export const ONE_TIME_PASSCODE_INPUT_LABEL =
  'We sent you a one-time confirmation code. To validate your phone number enter the 4 digit code below.';
export const ONE_TIME_PASSCODE_INPUT_NAME = 'ps-one-time-passcode-input';
export const ONE_TIME_PASSCODE_SUBMIT_BUTTON_TEXT = 'Submit code';
export const STATIC_PAGE_MESSAGES = {
  // Note: this also aligns with the default values we set on the BE so if we ever change the defaults
  // We'll need to update both this in the SDK & BE.
  EXISTING_SUBSCRIBER:
    "You're already subscribed! Thanks for being part of the community.",
  NEW_SUBSCRIBER: 'You are now subscribed to text messages!',
} as const;
export const STATIC_PAGE_TIMEOUT_DURATION = 2500;
export const STATIC_PAGE_AUTO_APPLY_TIMEOUT_DURATION = 3500;
export const WAIT_FOR_AUTO_APPLY_TIMEOUT_DURATION = 2000;
export const DOLLS_KILL_SHOP_ID = 336817;

// Ids/classes with this prefix are used as hooks for users who want to add custom CSS
// to the popup. Be careful when removing/changing elements with this prefix.
// Whenever possible try not to use these for internal styles, using high specificty
// selectors internally can end up overrideing a user's custom CSS.
export const CUSTOM_CSS_SELECTOR_PREFIX = 'ps-widget-custom-form__';

import { CLOUDFLARE_BUSINESS_PLAN_URL } from '../helpers/utility';

const SOURCE = 'Klaviyo Form Validation';
const EVENT = 'Klaviyo Form Submission';
const KLAVIYO_FORMS_EVENT_NAME = 'klaviyoForms';
const klaviyoSubmitEvents = ['submit', 'stepSubmit'];

export const addKlaviyoFormSubmissionEvent = (source = SOURCE) => {
  // Both submit and stepSubmit are fired on the first step, so this
  // prevents us from sending two opt in messages.
  let hasSentOptInEvent = false;

  window.addEventListener(KLAVIYO_FORMS_EVENT_NAME, (e) => {
    const shouldNotCreateEvent =
      !window.postscript ||
      !klaviyoSubmitEvents.includes(e.detail.type) ||
      !e.detail.metaData ||
      !e.detail.metaData.$phone_number ||
      hasSentOptInEvent;

    if (shouldNotCreateEvent) return;

    const payload = {
      accepts_sms: true,
      source,
      phone_number: e.detail.metaData.$phone_number,
      email: e.detail.metaData.$email,
      consent_method: e.detail.metaData.$consent_method,
      consent_form_id: e.detail.metaData.$consent_form_id || e.detail.formId,
      consent_form_version:
        e.detail.metaData.$consent_form_version || e.detail.formVersionId,
      klaviyo_source: e.detail.metaData.$source,
      sms_consent: e.detail.metaData.$sms_consent,
    };

    const delayExecution = false;

    window.postscript.event(EVENT, payload, delayExecution);

    hasSentOptInEvent = true;
  });
};

const getShopKlaviyoFormStatus = async (shopId) => {
  const response = await fetch(
    `${CLOUDFLARE_BUSINESS_PLAN_URL}/public/klaviyo_form_status/${shopId}`,
  );
  const { uses_forms: usesForms } = await response.json();

  return usesForms;
};

const addKlaviyoEvent = async (shopId) => {
  try {
    const shouldAddEvent = await getShopKlaviyoFormStatus(shopId);

    if (!shouldAddEvent) return;
    addKlaviyoFormSubmissionEvent(SOURCE);
  } catch (err) {
    // do nothing since the api endpoint might not exist yet
  }
};

export default addKlaviyoEvent;

import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, STYLE_RULE_NAMES } from '../constants';
import renderEditorNode from '../development/editorNode';
const CONTAINER_BLOCK_STYLE_ELEMENTS = Object.assign({}, BLOCK_STYLE_ELEMENT);
const defaultStyles = {
    [CONTAINER_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
            [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'flex-start',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MAX_WIDTH]: '100%',
            [STYLE_RULE_NAMES.PADDING]: '12px 12px 12px 12px',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'flex',
            [STYLE_RULE_NAMES.FLEX]: '1',
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, undeletableIds, }) => {
    if (!(undeletableIds === null || undeletableIds === void 0 ? void 0 : undeletableIds.includes(block.id)))
        // eslint-disable-next-line no-param-reassign
        blockActions.deleteBlock = () => { var _a; return (_a = popupActions.deleteBlock) === null || _a === void 0 ? void 0 : _a.call(popupActions, block.id); };
};
function isEmpty({ children = [] }) {
    return children.length === 0;
}
function render(renderData) {
    const { children, blockActions, classes, environment } = renderData;
    const { block: blockClasses, editorNode: editorNodeClasses } = classes;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      ${children}
    </div>
  `;
}
const containerBlockHelpers = {
    addBlockActions,
    defaultStyles,
    isEmpty,
    render,
    styleElements: CONTAINER_BLOCK_STYLE_ELEMENTS,
};
export default containerBlockHelpers;

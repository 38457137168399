import { html } from 'lit';

const successMessage = () => html`
  <section class="ps-section" data-cy="ps-success-section">
    <p id="ps-desktop-widget__success-pre-headline" class="ps-p">
      Check YOUR 📱 AND
    </p>
    <h4 id="ps-desktop-widget__success-headline" class="ps-heading">
      REPLY "Y"
    </h4>
    <p id="ps-desktop-widget__success-pre-headline" class="ps-p">
      TO FINISH SIGNING UP
    </p>
  </section>
`;

export default successMessage;

import { html } from 'lit';

const logoHeader = ({ logoUrl }) =>
  logoUrl
    ? html`
        <header>
          <img
            src="${logoUrl}"
            id="ps-desktop-widget__logo-image"
            alt="popup logo"
          />
        </header>
      `
    : null;

export default logoHeader;

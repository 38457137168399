import {
  INCLUSION_CRITERIA_OPTIONS,
  BlockPopup,
} from '@stodge-inc/block-rendering';

const CountrySelection = {
  ALL: 'All',
  ALL_OUTSIDE_US: 'All outside US',
  US: 'US',
  CANADA: 'Canada',
  US_AND_CANADA: 'US & Canada',
  ALL_OUTSIDE_US_AND_CANADA: 'All outside US and Canada',
  AUSTRALIA_AND_NEW_ZEALAND: 'Australia and New Zealand',
};

const allCountriesSpec = (countriesAllowed?: string | null) =>
  [CountrySelection.ALL, null, undefined].includes(countriesAllowed);

const usAndCanadaSpec = (
  currentCountry: string,
  countriesAllowed?: string | null,
) =>
  countriesAllowed === CountrySelection.US_AND_CANADA &&
  [CountrySelection.US, 'CA'].includes(currentCountry);

const sameCountrySpec = (
  currentCountry: string,
  countriesAllowed?: string | null,
) => countriesAllowed === currentCountry;

const canadaSpec = (currentCountry: string, countriesAllowed?: string | null) =>
  countriesAllowed === CountrySelection.CANADA && currentCountry === 'CA';

const allCountriesOutsideUsSpec = (
  currentCountry: string,
  countriesAllowed?: string | null,
) =>
  countriesAllowed === CountrySelection.ALL_OUTSIDE_US &&
  currentCountry !== CountrySelection.US;

const australiaAndNewZealandSepc = (
  currentCountry: string,
  countriesAllowed?: string | null,
) =>
  countriesAllowed === CountrySelection.AUSTRALIA_AND_NEW_ZEALAND &&
  ['AU', 'NZ'].includes(currentCountry);

const allCountriesOutsideUsAndCanadaSpec = (
  currentCountry: string,
  countriesAllowed?: string | null,
) =>
  countriesAllowed === CountrySelection.ALL_OUTSIDE_US_AND_CANADA &&
  !['US', 'CA'].includes(currentCountry);

export const isUserInAllowedCountry = (
  currentCountry: string,
  countriesAllowed?: string | null,
): boolean =>
  allCountriesSpec(countriesAllowed) ||
  usAndCanadaSpec(currentCountry, countriesAllowed) ||
  sameCountrySpec(currentCountry, countriesAllowed) ||
  canadaSpec(currentCountry, countriesAllowed) ||
  allCountriesOutsideUsSpec(currentCountry, countriesAllowed) ||
  allCountriesOutsideUsAndCanadaSpec(currentCountry, countriesAllowed) ||
  australiaAndNewZealandSepc(currentCountry, countriesAllowed);

export const blockPopupAllowsCurrentCountry = (
  currentCountry: string,
  countriesFilter: BlockPopup['countriesFilter'],
): boolean => {
  if (countriesFilter.items.length === 0) {
    return true;
  }

  const isCurrentCountryIncludedInFilterItems =
    countriesFilter.items.includes(currentCountry);

  return countriesFilter.criteria === INCLUSION_CRITERIA_OPTIONS.INCLUDED
    ? isCurrentCountryIncludedInFilterItems
    : !isCurrentCountryIncludedInFilterItems;
};

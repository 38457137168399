import {
  CASHBACK_CODE_APPLIED_NAME,
  CUSTOMER_DATA_SESSION_STORAGE_KEY,
} from '../helpers/constants';

export const postMessageCashbackApplied = (iframe: HTMLIFrameElement | null) =>
  iframe?.contentWindow?.postMessage(CASHBACK_CODE_APPLIED_NAME, '*');

export const getCustomerOrderCount = (): number | null => {
  try {
    const customerData = sessionStorage.getItem(
      CUSTOMER_DATA_SESSION_STORAGE_KEY,
    );
    if (customerData) {
      const customerDataObj = JSON.parse(customerData ?? '{}');
      return customerDataObj?.orderCount ?? null;
    }
  } catch (err) {
    // Do nothing
  }
  return null;
};

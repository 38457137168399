import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, BLOCK_TYPES, DEFAULT_COLORS, INPUT_STYLE_ELEMENT, LABEL_STYLE_ELEMENT, LABEL_TEXT_ELEMENT, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLE, } from '../constants';
import renderEditorNode from '../development/editorNode';
import { validateEmailFormat } from '../utils/data';
import { getEmptyEditorIntegration } from '../utils/emailIntegration';
import { renderError } from '../utils/errorOrSuccess';
const EMAIL_INPUT_TEXT_ELEMENTS = Object.assign({}, LABEL_TEXT_ELEMENT);
const EMAIL_INPUT_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign({}, LABEL_STYLE_ELEMENT), INPUT_STYLE_ELEMENT), BLOCK_STYLE_ELEMENT);
const defaultStyles = {
    [EMAIL_INPUT_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'flex',
            [STYLE_RULE_NAMES.HEIGHT]: 'fit-content',
            [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
            [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'flex-start',
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [EMAIL_INPUT_STYLE_ELEMENTS.INPUT]: {
        mobile: {
            // default variant uses the following styles
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.INPUT_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
        },
    },
    [EMAIL_INPUT_STYLE_ELEMENTS.LABEL]: {
        mobile: {
            // default variant uses the following styles
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '14px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 4px 0px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, stepBlocks, undeletableIds, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.updateStepData = (event) => {
        const { value } = event.target;
        popupActions.updateStepData(block.id, value);
    };
    if (!(undeletableIds === null || undeletableIds === void 0 ? void 0 : undeletableIds.includes(block.id)))
        // eslint-disable-next-line no-param-reassign
        blockActions.deleteBlock = () => {
            var _a, _b;
            const emailInputBlocks = (stepBlocks !== null && stepBlocks !== void 0 ? stepBlocks : []).filter((stepBlock) => stepBlock.type === BLOCK_TYPES.EMAIL_INPUT);
            const isLastEmailInputBlock = emailInputBlocks.length === 1;
            if (isLastEmailInputBlock) {
                (_a = blockActions.setEmailIntegration) === null || _a === void 0 ? void 0 : _a.call(blockActions, Object.assign({}, getEmptyEditorIntegration()));
            }
            (_b = popupActions.deleteBlock) === null || _b === void 0 ? void 0 : _b.call(popupActions, block.id);
        };
};
const validateBlockData = (block, data = '') => {
    var _a;
    if (!data && !((_a = block.config) === null || _a === void 0 ? void 0 : _a.required))
        return null;
    return validateEmailFormat(data);
};
function render(renderData) {
    var _a, _b;
    const { blockActions, block, classes, content, environment, state } = renderData;
    const { block: blockClasses, editorNode: editorNodeClasses, input: inputClasses, label: labelClasses, } = classes;
    const labelContent = (_a = content[EMAIL_INPUT_TEXT_ELEMENTS.LABEL]) !== null && _a !== void 0 ? _a : 'Email';
    const inputId = `input-${block.id}`;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      <label for=${inputId} class=${classMap(labelClasses)}>
        ${labelContent}
      </label>

      <input
        aria-describedby=${ifDefined((state === null || state === void 0 ? void 0 : state.error) ? `${inputId}-error` : undefined)}
        aria-invalid=${ifDefined(!!(state === null || state === void 0 ? void 0 : state.error))}
        autocomplete=${ifDefined(environment.isDevelopment ? undefined : 'email')}
        class=${classMap(inputClasses)}
        data-popup-engagement="true"
        id=${inputId}
        required=${ifDefined(((_b = block.config) === null || _b === void 0 ? void 0 : _b.required) ? true : undefined)}
        type="email"
        @change=${ifDefined(blockActions.updateStepData)}
      />

      ${renderError(state, inputId)}
    </div>
  `;
}
const emailInputBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: EMAIL_INPUT_STYLE_ELEMENTS,
    textElements: EMAIL_INPUT_TEXT_ELEMENTS,
    validateBlockData,
};
export default emailInputBlockHelpers;

import { SUBSCRIBER_ID_COOKIE_NAME } from '../../helpers/constants';
import { setCookie, CLOUDFLARE_BUSINESS_PLAN_URL } from '../../helpers/utility';
import { configStateStore } from '../../widget/common/helpers/stateManager';

const CACHE_KEY = 'ps_kl_ctx';
const KLAVIYO_CONTEXT_FEATURE_FLAG = 'sdk_klaviyo_context_checks';

const hasIdentityBeenFetchedInCurrentSession = (): boolean =>
  !!sessionStorage.getItem(CACHE_KEY);
const markIdentityAsFetchedForSession = (): void =>
  sessionStorage.setItem(CACHE_KEY, 'true');

export const getKlaviyoIdentity = async (shopId: number): Promise<void> => {
  const state = configStateStore.getState();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  const exchangeId = window._learnq?.push(['_getIdentifiers'])?.$exchange_id;
  const hasFlag = state.flags?.includes(KLAVIYO_CONTEXT_FEATURE_FLAG);
  const isIdentified = !!window.postscript?.getSubscriberId();

  const shouldFetchIdentity =
    !isIdentified &&
    hasFlag &&
    exchangeId &&
    !hasIdentityBeenFetchedInCurrentSession();

  if (!shouldFetchIdentity) {
    return;
  }

  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/public/subscriber/identify/klaviyo/?shopId=${shopId}&klaviyoExchangeId=${exchangeId}`,
    );

    if (!response.ok) {
      return;
    }

    const data = await response.json();
    if (data.id) {
      setCookie(SUBSCRIBER_ID_COOKIE_NAME, data.id, 3650);
    }
  } catch (err) {
    // Swallow errors to avoid breaking other features
  } finally {
    markIdentityAsFetchedForSession();
  }
};

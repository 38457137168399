import { PAGE_VIEW_EVENT } from '../../helpers/constants';
import {
  shouldTrackProductPageEvents,
  mapShopifyDataToProductPageEvent,
  getProduct,
  getVariantId,
} from './utils';

const trackPageViewForProducts = (shopId: string) => {
  window.Postscript.event(
    PAGE_VIEW_EVENT,
    mapShopifyDataToProductPageEvent(shopId),
  );
};

const listenForVariantChange = (shopId: string) => {
  let lastUrl = window.location.href;
  let lastVariantId = getVariantId(getProduct(), window.location.search);
  const observer = new MutationObserver(() => {
    const url = window.location.href;
    const variantId = getVariantId(getProduct(), window.location.search);

    if (url !== lastUrl || lastVariantId !== variantId) {
      lastUrl = url;
      lastVariantId = variantId;
      trackPageViewForProducts(shopId);
    }
  });
  observer.observe(document, { subtree: true, childList: true });
};

// Product page view events are used for browse abandonment automations, for SMS Sales insights
// and for segmentation
export const initializeProductPageView = async (shopId: string) => {
  if (!(await shouldTrackProductPageEvents())) {
    return;
  }

  trackPageViewForProducts(shopId);
  listenForVariantChange(shopId);
};

import { html } from 'lit';
import { DEFAULT_TERMS } from '../../../helpers/constants';
import { handleKeyDownForClickableDivs } from '../../../helpers/ui';
import exitButton from './exitButton';

const phonePartialOptInSuccess = ({
  handleExit,
  terms = DEFAULT_TERMS(window.parentHostname),
  logoUrl,
}) => html`
  <div
    id="ps-mobile-widget-partial__container"
    aria-modal="true"
    role="dialog"
    aria-labelledby="ps-mobile-widget-header-container"
  >
    ${exitButton({
      id: 'ps-mobile-widget-partial__close-button',
      onClick: handleExit,
    })}
    <div id="ps-mobile-widget-partial__logo-container">
      ${logoUrl
        ? html`<img
            id="ps-mobile-widget-partial__logo-image"
            src="${logoUrl}"
          />`
        : null}
    </div>
    <div id="ps-mobile-widget-partial__header-container">
      <section data-cy="ps-success-section">
        <p id="ps-mobile-widget-partial__success-pre-headline">
          Check YOUR 📱 AND
        </p>
        <h4 id="ps-mobile-widget-partial__success-headline" class="ps-heading">
          REPLY "Y"
        </h4>
        <p id="ps-mobile-widget-partial__success-post-headline">
          TO FINISH SIGNING UP
        </p>
      </section>
    </div>
    <div class="hidden" id="ps-mobile-widget-partial__terms">${terms}</div>
    <form id="ps-mobile-widget-partial__form-wrapper">
      <p
        style="text-align: center; margin: 0 auto;"
        tabindex="0"
        @click=${() => handleExit(true, true)}
        @keydown=${handleKeyDownForClickableDivs}
      >
        Close
      </p>
    </form>
  </div>
`;

export default phonePartialOptInSuccess;

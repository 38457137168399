import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
const renderEditorNode = (editorClasses, onDelete) => {
    const isDeletable = !!onDelete;
    return html `
    <div class=${classMap(editorClasses)}>
      ${isDeletable
        ? html `<div class="editor-node__button-row">
            <div class="editor-node__button" @click=${ifDefined(onDelete)}>
              <svg
                class="editor-node__button-svg"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.75 7.75l.841 9.673a2 2 0 001.993 1.827h5.832a2 2 0 001.993-1.827l.841-9.673H5.75zM9.75 10.75v5.5M13.25 10.75v5.5M8.75 7.75v-1a2 2 0 012-2h1.5a2 2 0 012 2v1M4.75 7.75h13.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>`
        : nothing}
    </div>
  `;
};
export default renderEditorNode;

import { html, nothing } from 'lit';
import { OTP_VERIFY_ERROR_STYLES } from '../constants';
/* For closed beta, we're just going to render errors with a static style. */
export const renderError = (blockState, inputId) => {
    const { error } = blockState !== null && blockState !== void 0 ? blockState : {};
    if (!error)
        return nothing;
    return html `<label
    for=${inputId}
    id=${`${inputId}-error`}
    style=${OTP_VERIFY_ERROR_STYLES}
    >${error}</label
  >`;
};
export const renderResendOtpSuccessMessage = (blockState, inputId) => {
    const { isResendOtpSuccessVisible } = blockState !== null && blockState !== void 0 ? blockState : {};
    if (!isResendOtpSuccessVisible)
        return nothing;
    return html `<span id=${`${inputId}-error`}>Code sent!</span>`;
};

import { html } from 'lit';
import { DEFAULT_TERMS } from '../../../helpers/constants';

const legalText = ({
  terms = DEFAULT_TERMS(window.parentHostname),
  isVisible = true,
  backInStock = false,
} = {}) => html`
  <article
    style="${backInStock
      ? isVisible
        ? null
        : 'display: none;'
      : isVisible
      ? 'visibility: visible;'
      : 'visibility: hidden;'}"
  >
    <div id="ps-desktop-widget__disclaimer-text">${terms}</div>
  </article>
`;

export default legalText;

import { GENERAL_REQUIRED_ERROR_MESSAGE, RESERVED_DATA_NAMES, } from '../constants';
const SUBSCRIBER_PROPERTY_PREFIX = 'subscriber_property_';
export const createSubscriberPropertyDataName = (dataName) => `${SUBSCRIBER_PROPERTY_PREFIX}${dataName}`;
export const isSubscriberPropertyDataName = (dataName) => dataName.startsWith(SUBSCRIBER_PROPERTY_PREFIX) ||
    dataName === RESERVED_DATA_NAMES.EMAIL;
export const removeSubscriberPropertyPrefix = (dataName) => dataName.replace(SUBSCRIBER_PROPERTY_PREFIX, '');
export const validateRequired = (block, data, errorMessage = GENERAL_REQUIRED_ERROR_MESSAGE) => {
    var _a;
    const isRequired = !!((_a = block.config) === null || _a === void 0 ? void 0 : _a.required);
    const hasData = !!data;
    return isRequired && !hasData ? errorMessage : null;
};
export const validateStringLength = (data, length, errorMessage = `This field must be ${length} characters long`) => {
    const hasRequiredLength = data.length === length;
    return !hasRequiredLength ? errorMessage : null;
};
export const validateEmailFormat = (data, errorMessage = 'Enter a valid email address') => {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    const isValidEmail = emailRegex.test(data);
    return !isValidEmail ? errorMessage : null;
};
/**
 * Removes all non-numeric characters from OTP code
 */
export const getSanitizedOtpValue = (data) => data.replace(/\D/g, '');

/* eslint-disable @typescript-eslint/no-explicit-any */
import { BlockPopup, SubscriberCohort } from '@stodge-inc/block-rendering';
import {
  POPUP_HARD_CLOSE_STATE,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  SUBSCRIBER_COHORTS,
} from '../../helpers/constants';
import {
  blockPopupAllowsCurrentCountry,
  isUserInAllowedCountry,
} from '../../helpers/targeting-utility';
import {
  checkPageIncluded,
  CLOUDFLARE_BUSINESS_PLAN_URL,
} from '../../helpers/utility';
import { STATIC_PAGE_MESSAGES } from '../../widget/forms/constants';
import { Popup } from '../../widget/types/popup';
import { DEFAULT_API_VALUES } from './constants';
import {
  GetValidBlockPopupsParams,
  LowerCasePopupType,
  MayHaveSplitTest,
} from './types';
import {
  getCookieValueByKey,
  groupPopupsBySplitTest,
  pickSplitTestByWeight,
} from '../../helpers/utils';

export const getUrlForShop = (
  shopId: number,
  popupType: LowerCasePopupType,
): URL => {
  const url = new URL(
    `/v2/public/popups/${shopId}/${popupType}`,
    CLOUDFLARE_BUSINESS_PLAN_URL,
  );
  return url;
};

export const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );

export const mapKeys = <T>(
  object: Record<string, T>,
  iteratee: (value: T, key: string, obj: Record<string, T>) => string,
): Record<string, T> => {
  const castObject = Object(object);
  const result: Record<string, T> = {};

  Object.keys(castObject).forEach((key) => {
    const value = castObject[key];
    result[iteratee(value, key, castObject)] = value;
  });
  return result;
};

export const camelCaseKeys = (object: any): Record<string, any> =>
  mapKeys(object, (v: any, key: any) => snakeToCamel(key));

export const camelCaseKeysDeep = (object: any): Record<string, any> => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((item) => camelCaseKeysDeep(item));
  }

  return camelCaseKeys(
    Object.entries(object).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: camelCaseKeysDeep(value),
      }),
      {},
    ),
  );
};

// Temporary until we run a migration to updates these in the DB
export const replaceLegacyItiClassesWithUpdatedClasses = (
  customCss?: string,
) => {
  if (!customCss) return customCss;

  let newCss = customCss;

  newCss = newCss.replace(
    /iti--separate-dial-code/g,
    'iti--show-selected-dial-code',
  );

  newCss = newCss.replace(/iti__selected-flag/g, 'iti__selected-country');

  newCss = newCss.replace(/iti__flag-container/g, 'iti__country-container');

  return newCss;
};

export const applyDefaultsToPopups = (
  popups: any,
  popupType: LowerCasePopupType,
): Array<Popup> => {
  const popupsWithDefaults = popups.map((data: any) => {
    const valuesWithDefaults = {
      ...DEFAULT_API_VALUES,
      ...data,
      button_radius: data.button_radius,
      font: data.font || DEFAULT_API_VALUES.font[popupType],
      subscriberCohort:
        data.subscriber_cohort || SUBSCRIBER_COHORTS.NEW_SMS_USERS,
      newSubscriberSuccessMessage:
        data.new_subscriber_success_message ||
        STATIC_PAGE_MESSAGES.NEW_SUBSCRIBER,
      existingSubscriberSuccessMessage:
        data.existing_subscriber_success_message ||
        STATIC_PAGE_MESSAGES.EXISTING_SUBSCRIBER,
      pages:
        data.pages?.map((page: any) => ({
          ...camelCaseKeys(page),
          questions: page.questions.map((q: any) => camelCaseKeys(q)),
        })) ?? [],
      customCss: replaceLegacyItiClassesWithUpdatedClasses(data.custom_css),
    };
    return camelCaseKeys(valuesWithDefaults);
  });
  return popupsWithDefaults;
};

export const filterSplitTestPopups = <T extends MayHaveSplitTest>(
  availablePopups: Array<T>,
  splitTestCookies: string | null,
): Array<T> => {
  const splitTestPopups = groupPopupsBySplitTest(availablePopups);

  const splitTestsToInclude = pickSplitTestByWeight(
    splitTestPopups,
    splitTestCookies,
  );

  const popupsWithoutSplitTests = availablePopups.filter(
    (popup) => !popup.splitTest,
  );

  const popupsToChooseFrom = [
    ...splitTestsToInclude,
    ...popupsWithoutSplitTests,
  ];

  return popupsToChooseFrom;
};

export const getPopupForPreview = (previewData: Popup) =>
  camelCaseKeys({
    ...DEFAULT_API_VALUES,
    ...previewData,
    includedPages: '',
    excludedPages: '',
    enabled: true,
    countriesAllowed: 'All',
  });

export const isUserInSubscriberCohort = (
  subscriberCohort: SubscriberCohort | null,
  subscriberId: string, // empty string when not subscribed
): boolean => {
  if (subscriberCohort === null) return true;

  const isNewCohort = subscriberCohort === SUBSCRIBER_COHORTS.NEW_SMS_USERS;
  const isExistingCohort =
    subscriberCohort === SUBSCRIBER_COHORTS.EXISTING_SMS_SUBSCRIBERS;

  /* Invalid scenarios:
    - cohort is for new subscribers and the user is already subscribed
    - cohort is for existing subscribers and the user is not subscribed
  */
  const isInvalid =
    (subscriberId && isNewCohort) || (!subscriberId && isExistingCohort);

  return !isInvalid;
};

export const selectAppropriatePopupForPage = (
  popupsResponse: Popup[],
  status: string,
  origin: string,
  currentCountry: string,
  subscriberId: string,
): Popup | null => {
  const filteredPopups = popupsResponse.filter((popup) => {
    if (!checkPageIncluded(origin, popup.includedPages, popup.excludedPages)) {
      return false;
    }

    if (!isUserInSubscriberCohort(popup?.subscriberCohort, subscriberId)) {
      return false;
    }

    if (!isUserInAllowedCountry(currentCountry, popup.countriesAllowed)) {
      return false;
    }

    const currentPopupStatus = getCookieValueByKey(
      POPUP_STATUS_COOKIE_NAME_PREFIX,
      popup.id,
      status,
    );

    if (currentPopupStatus === POPUP_HARD_CLOSE_STATE) {
      return false;
    }

    return true;
  });

  // Find the first one that has excluded/included pages set then
  // Prefer popups that are explicitly included on the current page
  let match = filteredPopups.find((popup) => popup.includedPages);

  if (!match) {
    // eslint-disable-next-line prefer-destructuring
    match = filteredPopups[0];
  }

  return match || null;
};

export const getValidBlockPopups = ({
  blockPopups,
  currentCountry,
  origin,
  statuses,
  subscriberId,
}: GetValidBlockPopupsParams): BlockPopup[] =>
  blockPopups.filter(
    ({
      countriesFilter,
      excludedPages,
      id,
      includedPages,
      subscriberCohort,
    }) => {
      /* Included/excluded pages are strings in v2, and we share logic, so the
      equivalent properties need to be converted to strings from arrays. */
      const includedPagesAsString = includedPages.join(',');
      const excludedPagesAsString = excludedPages.join(',');
      const status = getCookieValueByKey(
        POPUP_STATUS_COOKIE_NAME_PREFIX,
        id,
        statuses,
      );

      const isValidUrl = checkPageIncluded(
        origin,
        includedPagesAsString,
        excludedPagesAsString,
      );

      const isValidCountry = blockPopupAllowsCurrentCountry(
        currentCountry,
        countriesFilter,
      );

      const isValidStatus = status !== POPUP_HARD_CLOSE_STATE;

      const isValidSubscriberCohort = isUserInSubscriberCohort(
        subscriberCohort,
        subscriberId,
      );

      return (
        isValidUrl && isValidCountry && isValidStatus && isValidSubscriberCohort
      );
    },
  );

export const getBlockPopupByTriggerType = (
  blockPopups: BlockPopup[],
  type: string,
): BlockPopup | null => {
  const popupsWithCorrectTrigger = blockPopups.filter(
    (popup) => popup.trigger.type === type,
  );
  // Prioritize popups which explicitly configured included pages
  const popupConfiguredExplicitlyForCurrentPage = popupsWithCorrectTrigger.find(
    (popup) => popup.includedPages.length > 0,
  );

  return (
    popupConfiguredExplicitlyForCurrentPage ||
    popupsWithCorrectTrigger[0] ||
    null
  );
};

import { html } from 'lit';
import mapPopupPropertiesToCssVars from '../../common/popupCssVariable';
import { POPUP_NEW_COMPLIANCE_TEXT } from '../../../helpers/constants';
import { configStateStore } from '../../common/helpers/stateManager';

export const adjustMobileFont = (
  font,
  headlineFontSize,
  postHeadlineFontSize,
  defaultFontOverrides,
) => {
  // Hack to apply different default headline font size for open sans
  const getHeadlineFontSize = () => {
    const OPEN_SANS_CONDENSED = 'Open Sans Condensed:300';
    const DEFAULT_HEADLINE_FONT_SIZE = 14;
    const DEFAULT_OPEN_SANS_HEADLINE_FONT_SIZE = 19;

    // If font size is set just use that
    if (headlineFontSize) {
      return headlineFontSize;
    }

    // If font size isn't set and font is open sans
    if (font === OPEN_SANS_CONDENSED) {
      return DEFAULT_OPEN_SANS_HEADLINE_FONT_SIZE;
    }

    // Otherwise return default
    return DEFAULT_HEADLINE_FONT_SIZE;
  };

  const DEFAULT_FONT_ADJUSTMENTS = {
    preHeadline: '5.5vw',
    headline: `${getHeadlineFontSize()}vw`,
    postHeadline: `${postHeadlineFontSize}vw`,
    cta: '5.5vw',
    terms: '2.5vw',
    ...defaultFontOverrides,
  };

  const FONTS = [
    'Open Sans Condensed:300',
    'Merriweather',
    'Arial',
    'Roboto Mono',
    'Nunito Sans',
    'Roboto Slab',
    'Playfair Display',
    'Times New Roman',
    'Dancing Script',
    'Arimo',
    'EB Garamond',
    'Oswald',
    'Gothic A1',
    'IBM Plex Sans',
    'Kameron',
    'Cinzel',
    'Courier Prime',
    'Lato',
    'Muli',
    'PT Sans',
    'Roboto',
    'Inter',
  ];

  const fontSizes = FONTS.reduce(
    (agg, font) =>
      Object.assign(agg, { [font]: { ...DEFAULT_FONT_ADJUSTMENTS } }),
    {},
  );

  // Custom overrides
  fontSizes['Courier Prime'] = {
    ...fontSizes['Courier Prime'],
    preHeadline: '6vw',
    cta: '6vw',
  };

  return fontSizes;
};

/**
 * All of the static and dynamic CSS is applying the updated (version 2) defaults.
 * We don't want v1 popups to change, so we reapply the old CSS at the end but before custom css.
 */
const getVersionOnePopupOverrides = () => `
  #ps-mobile-widget-partial__success-pre-headline,
  #ps-mobile-widget-partial__success-post-headline,
  #ps-mobile-widget-partial__sub-headline {
    margin-bottom: revert;
    margin-top: 10px;
  }

  #ps-mobile-widget-partial__success-pre-headline {
    margin-bottom: 0.5rem;
  }

  #ps-mobile-widget-partial__terms {
    margin: 0;
    padding: 7px;
  }

  #ps-mobile-widget-partial__container {
    bottom: 0;
    top: unset;
    transform: unset;
    padding: 0;
  }

  #ps-mobile-widget-partial__header-container {
    padding: 0px 10vw;
  }

  #ps-mobile-widget-partial__form-wrapper {
    margin: revert;
    padding: 16px;
  }

  #ps-mobile-widget-partial__buttons-container {
	  padding: 0 10vw 18px;
  }

  #ps-mobile-widget-partial__close-button {
    position: revert;
  }

  #ps-mobile-widget-partial__phone-submit {
    padding: 7px;
  }

  #ps-mobile-widget-partial__email-accept {
    padding: 12px;
  }

  #ps-mobile-widget-partial__email-input-wrapper {
    padding: 16px;
  }

  .ps-mobile-widget-partial__email-input,
  #ps-mobile-widget-partial__email-accept {
    padding: 12px;
  }

  #ps-mobile-widget-partial__email-accept > svg,
  #ps-mobile-widget-partial__phone-submit > svg {
    width: 24px;
  }

  #ps-mobile-widget-overlay__container {
    display: block;
    padding: 0;
    padding-top: 10px;
  }

  #ps-mobile-widget-overlay__logo-container {
    height: 15vh;
    margin-bottom: 0;
  }

  #ps-mobile-widget-overlay__header-container {
    padding: 0px 10vw;
  }

  #ps-mobile-widget-overlay__terms-container {
    padding: 20px 5vw;
    margin: 0;
  }

  .phone #ps-mobile-widget-overlay__phone-input-wrapper > .iti {
    padding: 20px 15px;
  }

  #ps-mobile-widget-overlay__phone-input-wrapper > .iti {
    padding: 8px;
  }

  .ps-mobile-widget-overlay__email-input {
    padding: 20px 15px;
  }

  #ps-mobile-widget-overlay__reject-button,
  #ps-mobile-widget-overlay__accept-button {
    padding: 20px 15px;
  }

  .email #ps-mobile-widget-overlay__reject-button,
  .phone #ps-mobile-widget-overlay__reject-button {
    margin: 0;
  }

  #ps-mobile-widget-overlay__buttons-container {
    padding: 0px 10vw;
  }

  #ps-mobile-widget-overlay__buttons-container--opt-in {
    padding: 0px 10vw;
    margin: revert;
  }
`;

const dynamicStyles = (popupThemeSettings) => {
  const {
    font,
    customCss,
    backgroundImage,
    borderRadius,
    buttonBackgroundColor,
    buttonTextColor,
    textColor,
    buttonRadius,
    backgroundStyle,
    style,
    headlineFontSize,
    postHeadlineFontSize = 5.5,
    styleDefaultVersion,
    logoUrl,
  } = popupThemeSettings;
  const { preHeadline, headline, postHeadline, terms, cta } = adjustMobileFont(
    font,
    headlineFontSize,
    postHeadlineFontSize,
  )[font];

  const configManager = configStateStore.getState();
  const { flags } = configManager;
  const hasNewComplianceText = flags?.includes(POPUP_NEW_COMPLIANCE_TEXT);

  // TODO: Fix custom font support. We are duplicating customCss at the top
  // and bottom of the file to support both css imports and cascade order.
  const template = `
    ${customCss || ''}
    #ps__container, #ps-mobile-widget__container, ::placeholder {
      font-family: ${font.split(':')[0]}, sans-serif;
    }

    ${
      logoUrl
        ? `
        #ps-mobile-widget-overlay__logo-container {
          margin-bottom: 24px;
        }
      `
        : ''
    }

    #ps-mobile-widget-overlay__pre-headline {
      font-size: ${preHeadline};
    }

    #ps-mobile-widget-overlay__headline,
    #ps-mobile-widget-partial__headline {
      font-size: ${headline};
    }

    #ps-mobile-widget-overlay__post-headline,
    #ps-mobile-widget-partial__sub-headline {
      font-size: ${postHeadline};
    }

    #ps-mobile-widget-overlay__terms,
      #ps-mobile-widget-partial__terms {
      font-size: ${terms};
    }

    #ps-mobile-widget-overlay__accept-button,
    #ps-mobile-widget-overlay__reject-button,
    #ps-mobile-widget-partial__accept-button {
      font-size: ${cta};
    }

    ${
      backgroundStyle === 'Image'
        ? `
        #ps-mobile-widget-partial__container,
        #ps-mobile-widget-overlay__container {
          background-image: url(${backgroundImage});
        }
      `
        : ''
    }

    ${
      backgroundImage
        ? `
        #ps-mobile-widget-overlay__pre-headline,
        #ps-mobile-widget-overlay__post-headline {
          font-weight: bold;
        }
      `
        : ''
    }

    #ps-mobile-widget-partial__container,
    #ps-mobile-widget-overlay__container {
      color: ${textColor};
    }

    #ps-mobile-widget-partial__container {
      border-radius: ${borderRadius}px;
    }

    ::placeholder {
      color: ${textColor};
    }

    #ps-mobile-widget-partial__accept-button,
    #ps-mobile-widget-overlay__accept-button,
    #ps-mobile-widget-partial__email-accept {
      border-color: ${buttonBackgroundColor};
    }

    #ps-mobile-widget-partial__email-accept > svg,
    #ps-mobile-widget-partial__phone-submit > svg {
      color: ${buttonTextColor};
    }

    #ps-mobile-widget-overlay__phone-input-wrapper,
    #ps-mobile-widget-partial__accept-button {
      border-radius: ${style === 'Square' ? 0 : buttonRadius}px;
    }

    .ps-mobile-widget-partial__email-input,
    #ps-mobile-widget-overlay__phone-input-wrapper > .iti,
    #ps-mobile-widget-partial__phone-input-wrapper > .iti {
      border-color: ${buttonBackgroundColor};
      border-top-left-radius: ${buttonRadius}px;
      border-bottom-left-radius: ${buttonRadius}px;
    }

    #ps-mobile-widget-overlay__phone-input-wrapper > .iti,
    .ps-mobile-widget-overlay__email-input,
    #ps-mobile-widget-overlay__buttons--opt-in,
    #ps-mobile-widget-overlay__accept-button {
      border-radius: ${buttonRadius}px;
    }

    #ps-mobile-widget-overlay__buttons--opt-in,
    #ps-mobile-widget-overlay__accept-button,
    #ps-mobile-widget__email-input {
      border-color: ${buttonBackgroundColor};
    }

    #ps-mobile-widget-partial__email-accept,
    #ps-mobile-widget-partial__phone-submit {
      border-color: ${buttonBackgroundColor};
      border-top-right-radius: ${buttonRadius}px;
      border-bottom-right-radius: ${buttonRadius}px;
    }

    ${
      hasNewComplianceText
        ? `
        #ps-mobile-widget-partial__terms {
          font-size: 2.3vw;
        }
      `
        : ''
    }

    ${mapPopupPropertiesToCssVars(popupThemeSettings)}

    ${(styleDefaultVersion ?? 1) === 1 ? getVersionOnePopupOverrides() : ''}

    ${customCss || ''}
`.trim();

  return html`
    <style>
      ${template}
    </style>
  `;
};

export default dynamicStyles;

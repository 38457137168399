import { html } from 'lit';
import { BIS_FADE_IN_CLASS } from '../../../../helpers/constants';
import { handleKeyDownForClickableDivs } from '../../../../helpers/ui';

/**
 * @typedef {object} ClosedBubbleProps
 * @prop {string} text
 * @prop {() => void} onOpen
 * @prop {() => void} onExit
 */

/**
 * @param {ClosedBubbleProps} props
 */
const closedBubble = ({ text, onOpen, onExit }) => html`
  <div
    id="ps-bis-widget-bubble__container"
    class="${BIS_FADE_IN_CLASS} bubble__container"
  >
    <div
      id="ps-bis-widget-bubble__close-container"
      data-cy="ps-bis-widget-bubble__close-container"
      class="bubble__close-container"
    >
      <div
        id="ps-bis-widget-bubble__close-text"
        class="bubble__close-text"
        role="button"
        tabindex="0"
        data-cy="popup-open-button"
        @click=${onOpen}
        @keydown=${handleKeyDownForClickableDivs}
      >
        ${text}
      </div>
      <div
        id="ps-bis-widget-bubble__close-widget"
        role="button"
        tabindex="0"
        aria-label="Dismiss marketing prompt"
        data-cy="popup-dismiss-button"
        @click=${onExit}
        @keydown=${handleKeyDownForClickableDivs}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </div>
    </div>
  </div>
`;

export default closedBubble;

import { html, svg } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { DEFAULT_COLORS, STYLE_ELEMENT_TYPES, STYLE_RULE_NAMES, } from '../../constants';
import { getAdjustedStopperHeight } from '../../utils/spinToWin';
const STOPPER_PATHS = {
    TEAR: 'M35.5 0L54.9478 41.4677C61.6153 55.6846 51.2231 72 35.5 72C19.7769 72 9.38466 55.6846 16.0522 41.4678L35.5 0Z',
    TEAR_ROUNDED: 'M30.674 3.602c1.867-4.803 8.785-4.803 10.652 0l15.206 39.102C62.03 56.839 51.404 72 36 72S9.97 56.839 15.468 42.704L30.674 3.602Z',
    TRIANGLE: 'M35.5 0 64 72H7L35.5 0Z',
    TRIANGLE_ROUNDED: 'M31.35 2.697c1.461-3.705 6.815-3.549 8.169.197 8.864 24.522 15.116 33.443 23.982 57.98C65.47 66.321 61.367 72 55.568 72H16.526C10.519 72 6.4 65.959 8.6 60.378l22.75-57.68Z',
};
export const defaultStyles = {
    [STYLE_ELEMENT_TYPES.PATH]: {
        mobile: {
            [STYLE_RULE_NAMES.FILL]: DEFAULT_COLORS.STOPPER,
            [STYLE_RULE_NAMES.STROKE]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.STROKE_WIDTH]: '2px',
        },
    },
    [STYLE_ELEMENT_TYPES.SVG]: {
        mobile: {
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'block',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
            [STYLE_RULE_NAMES.TRANSFORM]: 'translate(-50%, 0) rotate(180deg)', // Rotate after translate
        },
    },
};
const spinToWinStopper = ({ finalStyleRules, position, psCssClasses, stopperRotationOffset, type, }) => {
    var _a, _b;
    /* SVG strokes cover the inside and outside of a path. To replicate CSS
    border-box logic we double the stroke, then clip the exterior so that it looks
    like the border is growing inward at the size the user chose. */
    const stopperStrokeWidth = (_b = (_a = finalStyleRules[STYLE_ELEMENT_TYPES.PATH]) === null || _a === void 0 ? void 0 : _a.strokeWidth) !== null && _b !== void 0 ? _b : '0';
    const doubleStroke = `calc(${stopperStrokeWidth} * 2)`;
    const height = getAdjustedStopperHeight(position);
    const inset = `calc(${height} / -2) auto auto 50%`;
    return html `
    <div
      class="spin-to-win__stopper-wrapper"
      style="transform: rotate(${stopperRotationOffset}deg);"
    >
      ${svg `
        <svg
          class=${classMap(psCssClasses.stopperSvgClasses)}
          fill="none"
          style="height: ${height}; inset: ${inset};"
          viewBox="0 0 72 72"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <clipPath id="clip-exterior-of-stroke">
              <path d=${STOPPER_PATHS[type]} />
            </clipPath>
          </defs>
          <path
            class=${classMap(psCssClasses === null || psCssClasses === void 0 ? void 0 : psCssClasses.stopperPathClasses)}
            clip-path="url(#clip-exterior-of-stroke)"
            d=${STOPPER_PATHS[type]}
            fill="none"
            style="stroke-width: ${doubleStroke}"
            vector-effect="non-scaling-stroke"
          />
        </svg>
      `}
    </div>
  `;
};
export default spinToWinStopper;

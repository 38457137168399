import { v4 as uuidv4 } from 'uuid';
import {
  SESSION_SITE_VISIT_COOKIE_NAME,
  SITE_VISIT_COOKIE_NAME,
} from '../../helpers/constants';
import { getCookie, setCookie } from '../../helpers/utility';
import { PAGE_EVENT_TYPES } from '../../widget/common/block-popups/constants';
import {
  PlatformType,
  postPageEvent,
} from '../../widget/common/block-popups/utils/api';
import { canUserBeTracked } from '../../helpers/customerPrivacy';

const TEN_YEARS_IN_DAYS = 3650;
const SESSION_TIMEOUT_MS = 30 * 60 * 1000;

interface SessionData {
  sessionId: string;
  startTime: number;
}

export const trackSessionAnalytics = async (
  shopId: string,
  platform: PlatformType,
  shopifyGlobal?: ShopifyGlobals,
) => {
  try {
    const canTrack = await canUserBeTracked(shopifyGlobal);
    let sessionData: SessionData | null = null;

    if (canTrack) {
      const sessionCookieValue = getCookie(SESSION_SITE_VISIT_COOKIE_NAME);
      sessionData = sessionCookieValue ? JSON.parse(sessionCookieValue) : null;
    }

    const now = Date.now();
    const sessionExpired = !!(
      sessionData && now - sessionData.startTime > SESSION_TIMEOUT_MS
    );

    // If there's no session OR the existing session expired, fire a new SESSION event
    if (!sessionData || sessionExpired) {
      sessionData = {
        sessionId: uuidv4(),
        startTime: now,
      };
      // Fire the event, even if the user opted out of cookie tracking
      postPageEvent(PAGE_EVENT_TYPES.SESSION, platform, Number(shopId));
    }

    // Only set/update the cookie if canTrack is true
    if (canTrack && sessionData) {
      setCookie(
        SESSION_SITE_VISIT_COOKIE_NAME,
        JSON.stringify(sessionData),
        0,
        0,
        false,
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to track session analytics', error);
  }
};

export const trackUniqueVisitorAnalytics = async (
  shopId: string,
  platform: PlatformType,
  shopifyGlobal?: ShopifyGlobals,
) => {
  try {
    const canTrack = await canUserBeTracked(shopifyGlobal);

    const uniqueVisitorCookie = canTrack
      ? getCookie(SITE_VISIT_COOKIE_NAME)
      : null;

    // If there’s no cookie, we still fire the unique visitor event; set the cookie only if canTrack is true
    if (!uniqueVisitorCookie) {
      postPageEvent(PAGE_EVENT_TYPES.UNIQUE_VISITOR, platform, Number(shopId));

      if (canTrack) {
        setCookie(SITE_VISIT_COOKIE_NAME, 'true', TEN_YEARS_IN_DAYS);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to track unique visitor analytics', error);
  }
};

export const initializePageAnalytics = (
  shopId: string,
  platform: PlatformType,
  shopifyGlobal?: ShopifyGlobals,
) => {
  trackSessionAnalytics(shopId, platform, shopifyGlobal);
  trackUniqueVisitorAnalytics(shopId, platform, shopifyGlobal);
};

import intlTelInput, { SomeOptions } from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const updatePhoneInput = (
  countryCode: string | null,
  selector = '#ps-desktop-widget__phone-input',
  config: SomeOptions = {},
) => {
  const input = document.querySelector(selector);

  if (!input) return null;

  return intlTelInput(input as HTMLInputElement, {
    countrySearch: false,
    fixDropdownWidth: false,
    formatAsYouType: false,
    initialCountry: countryCode ?? 'US',
    nationalMode: false,
    preferredCountries: ['us', 'ca'],
    showSelectedDialCode: true,
    utilsScript:
      'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/21.2.8/js/utils.js',
    ...config,
  });
};

export default updatePhoneInput;

import { SPIN_TO_WIN_STOPPER_POSITIONS, SPIN_TO_WIN_STOPPER_SIZE, } from '../constants';
const getDegreesPerSection = (sections) => 360 / sections.length;
export const getMedianSectionAngle = (sections) => getDegreesPerSection(sections) / 2;
export const getRotationForSection = (index, sections) => getDegreesPerSection(sections) * index;
export const getRotationForText = (index, sections) => getDegreesPerSection(sections) * index + getMedianSectionAngle(sections);
export const getAnimationRotation = ({ rotations, sections, startingSection, }) => {
    // The last revolution will not be a full spin, every other revolution is
    const arbitrarySpins = rotations * 360;
    // Angle to winning section
    const lastSpin = getDegreesPerSection(sections) * startingSection;
    // Adjust to land off-center
    const lastSpinAdjusted = lastSpin - getMedianSectionAngle(sections) / 3;
    return arbitrarySpins + lastSpinAdjusted;
};
/**
 * Makes room for the stopper on the edges of the block. When the stopper is
 * left or right position there is padding on both sides so it stays centered,
 * but when top or bottom is used, we only add padding to the side it's being
 * used on.
 */
export const getOuterMarginAdjustedToStopperPosition = (position) => {
    const isHorizontalStopper = position === SPIN_TO_WIN_STOPPER_POSITIONS.LEFT ||
        position === SPIN_TO_WIN_STOPPER_POSITIONS.RIGHT;
    const margin = `calc(${SPIN_TO_WIN_STOPPER_SIZE} / 2)`;
    return isHorizontalStopper
        ? `margin: 0 ${margin}`
        : `margin-${position.toLowerCase()}: ${margin}`;
};
/* When the stopper is horizontal, we add margins to the top and bottom of the
div equal to the same percentage that that determines the stopper's height.
However, the stopper's rendered height is calculated after the div is rotated,
and thusly making there less available height. This results in the size of the
stopper not being equal to the margin that is added (only when horizontal). To
compensate for this, we do an adjustment with this function. */
export const getAdjustedStopperHeight = (position) => {
    const isHorizontalStopper = position === SPIN_TO_WIN_STOPPER_POSITIONS.LEFT ||
        position === SPIN_TO_WIN_STOPPER_POSITIONS.RIGHT;
    return isHorizontalStopper
        ? `${(parseFloat(SPIN_TO_WIN_STOPPER_SIZE) / 100 + 1) *
            parseFloat(SPIN_TO_WIN_STOPPER_SIZE)}%`
        : SPIN_TO_WIN_STOPPER_SIZE;
};

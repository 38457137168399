import { html } from 'lit';
import phoneFullscreenOptInSuccess from './phoneFullscreenOptInSuccess';
import { DEFAULT_TERMS } from '../../../helpers/constants';
import exitButton from './exitButton';
import phoneButtonBar from './phoneButtonBar';

const phoneFullscreenOptIn = ({
  handleExit,
  closeText,
  onSubmitOptIn,
  logoUrl,
  preHeadline,
  postHeadline,
  headline,
  subscribeText,
  showSuccess,
  terms = DEFAULT_TERMS(window.parentHostname),
  subscriberId,
}) => {
  if (showSuccess) return phoneFullscreenOptInSuccess({ handleExit, logoUrl });

  return html`
    <div
      id="ps-mobile-widget-overlay__container"
      aria-modal="true"
      role="dialog"
      aria-labelledby="ps-mobile-widget-overlay__headline"
      aria-describedby="ps-mobile-widget-overlay__header-container"
    >
      <div id="ps-mobile-widget-overlay__close-container">
        ${exitButton({
          id: 'ps-mobile-widget-overlay__close-button',
          onClick: handleExit,
        })}
      </div>
      <div id="ps-mobile-widget-overlay__logo-container">
        ${logoUrl
          ? html`
              <img id="ps-mobile-widget-overlay__logo-image" src="${logoUrl}" />
            `
          : null}
      </div>
      <div id="ps-mobile-widget-overlay__header-container">
        <hgroup id="ps-mobile-widget-overlay__header">
          <h3 id="ps-mobile-widget-overlay__pre-headline" class="ps-heading">
            ${preHeadline}
          </h3>
          <h2 id="ps-mobile-widget-overlay__headline" class="ps-heading">
            ${headline}
          </h2>
          <h3 id="ps-mobile-widget-overlay__post-headline" class="ps-heading">
            ${postHeadline}${postHeadline && '*'}
          </h3>
        </hgroup>
      </div>
      <div
        id="ps-mobile-widget-overlay__terms-container"
        class="${subscriberId ? 'ps-hide-visibility' : ''}"
      >
        <div id="ps-mobile-widget-overlay__terms">${terms}</div>
      </div>
      <form
        @submit=${onSubmitOptIn}
        id="ps-mobile-widget-overlay__buttons-container--opt-in"
      >
        <div
          id="ps-mobile-widget-overlay__phone-input-wrapper"
          class="${subscriberId ? 'ps-hide-visibility' : ''}"
          style="width: 100%;"
          data-popup-engagement="true"
        >
          <input
            aria-label="Enter your phone number"
            aria-describedby="ps-mobile-widget-overlay__terms"
            id="ps-mobile-widget-overlay__phone-input"
            type="tel"
            data-cy="phone-input"
          />
        </div>
        ${phoneButtonBar({
          closeText,
          subscribeText,
          subscriberId,
          handleExit,
        })}
      </form>
    </div>
  `;
};

export default phoneFullscreenOptIn;

import { html } from 'lit';
import { DEFAULT_TERMS } from '../../../helpers/constants';
import { handleKeyDownForClickableDivs } from '../../../helpers/ui';
import exitButton from './exitButton';

const phoneFullscreenOptIn = ({
  handleExit,
  logoUrl,
  terms = DEFAULT_TERMS(window.parentHostname),
}) => html`
  <div
    id="ps-mobile-widget-overlay__container"
    aria-modal="true"
    role="dialog"
    aria-labelledby="ps-mobile-widget-overlay__header-container"
  >
    <div id="ps-mobile-widget-overlay__close-container">
      ${exitButton({
        id: 'ps-mobile-widget-overlay__close-button',
        onClick: handleExit,
      })}
    </div>
    <div id="ps-mobile-widget-overlay__logo-container">
      ${logoUrl
        ? html`
            <img id="ps-mobile-widget-overlay__logo-image" src="${logoUrl}" />
          `
        : null}
    </div>
    <div id="ps-mobile-widget-overlay__header-container">
      <section data-cy="ps-success-section">
        <p id="ps-mobile-widget-overlay__pre-headline">Check YOUR 📱 AND</p>
        <h4 id="ps-mobile-widget-overlay__headline" class="ps-heading">
          REPLY "Y"
        </h4>
        <p id="ps-mobile-widget-overlay__post-headline">TO FINISH SIGNING UP</p>
      </section>
    </div>
    <div class="hidden" id="ps-mobile-widget-overlay__terms-container">
      <div id="ps-mobile-widget-overlay__terms">${terms}</div>
    </div>
    <div id="ps-mobile-widget-overlay__buttons-container--opt-in">
      <div
        class="hidden"
        id="ps-mobile-widget-overlay__phone-input-wrapper"
        style="width: 100%;"
      >
        <input
          aria-label="Enter your phone number"
          id="ps-mobile-widget-overlay__phone-input"
          type="tel"
        />
      </div>
      <div id="ps-mobile-widget-overlay__buttons--opt-in">
        <div
          tabindex="0"
          @click=${() => handleExit(true, true)}
          @keydown=${handleKeyDownForClickableDivs}
          role="button"
          id="ps-mobile-widget-overlay__accept-button"
        >
          <span id="ps-mobile-widget-overlay__accept-button-text">Thanks!</span>
        </div>
      </div>
    </div>
  </div>
`;

export default phoneFullscreenOptIn;

import { html } from 'lit';
import { handlePopupKeyDown } from '../../../../../helpers/ui';
import {
  BUNDLED_STYLES_SELECTOR,
  BIS_ROOT_ID,
} from '../../../../../helpers/constants';
import successMessage from '../../../../../widget/desktop/components/successMessage';
import submitButton from '../../../../../widget/desktop/components/submitButton';
import headlineSection from '../../../../../widget/desktop/components/headlineSection';
import logoHeader from '../../../../../widget/desktop/components/logoHeader';
import legalText from '../../../../../widget/desktop/components/legalText';
import exitButton from '../../../../../widget/desktop/components/exitButton';
import phoneInput from '../../../../../widget/desktop/components/phoneInput';
import { addBundledStyles } from '../../../utils';

/**
 * @typedef {object} DesktopModalProps
 * @prop {() => void} onExit
 * @prop {(evt) => void} onSubmit
 * @prop {string} subscriberId
 * @prop {boolean} showSuccess
 */

/**
 * @param {import("../../widget").PopupPreferences & DesktopModalProps} props
 */
const desktopModal = ({
  onExit,
  onSubmit,
  subscriberId,
  showSuccess,
  headline,
  logoUrl,
  buttonText,
}) => {
  addBundledStyles(BUNDLED_STYLES_SELECTOR);

  return html`
    <div
      id="ps-bis-widget-modal__container"
      class="ps-desktop-modal__container"
      data-cy="ps-bis-widget-modal__container"
      aria-modal="true"
      role="dialog"
      aria-labelledby="ps-desktop-widget__headline"
      aria-describedby="desktop-widget__aria-description"
      @keydown=${handlePopupKeyDown(onExit, BIS_ROOT_ID)}
    >
      <div
        id="ps-bis-widget-modal__background"
        class="modal__background ps-desktop-modal__background"
      >
        <div
          id="ps-bis-widget-modal__click-background"
          class="ps-desktop-modal__click-background"
          @click=${onExit}
        ></div>
        <div
          id="ps-bis-widget-modal__body"
          class="ps-desktop-modal__body"
          data-cy="desktop-popup-container"
        >
          <div id="ps-bis-widget-modal__body-view">
            ${exitButton({ onClick: onExit })} ${logoHeader({ logoUrl })}
            ${!showSuccess
              ? headlineSection({
                  headline,
                  screen: 'phone',
                  backInStock: true,
                })
              : successMessage()}
            <form @submit=${onSubmit}>
              ${phoneInput({
                isVisible: !showSuccess && !subscriberId,
                inputId: 'ps-bis-widget-modal__phone-input',
                backInStock: true,
              })}
              ${legalText({
                isVisible: !showSuccess && !subscriberId,
                backInStock: true,
              })}
              ${submitButton({ text: buttonText, isVisible: !showSuccess })}
            </form>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default desktopModal;

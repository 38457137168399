import { captureException } from '@sentry/browser';
import { CustomFont, OptInMethod } from '@stodge-inc/block-rendering';
import {
  CLOUDFLARE_BUSINESS_PLAN_URL,
  CLOUDFLARE_ENTERPRISE_PLAN_URL,
} from '../../../../helpers/utility';
import {
  camelCaseKeys,
  camelCaseKeysDeep,
} from '../../../../services/popup/helper';
import { MayHaveSplitTest } from '../../../../services/popup/types';
import { PageEventTypes, PopupEventTypes } from '../types';
import {
  BlockPopupOptInProps,
  PsnOptInRequest,
  PsnOptInResponse,
  VerifyResponse,
} from '../../../../helpers/types';
import { BlockPopup } from '../../../../types/blockPopup';

const ANALYTICS_SERVICE_URL = process.env.POPUP_ANALYTICS_SERVICE_URL;

export type PlatformType = 'MOBILE' | 'DESKTOP';

const adaptForV2TargetingLogic = (
  blockPopup: BlockPopup,
): BlockPopup & MayHaveSplitTest => {
  if (blockPopup.splitTest === undefined || blockPopup.splitTest === null) {
    return {
      ...blockPopup,
      splitTest: blockPopup.splitTest,
    };
  }

  return {
    ...blockPopup,
    splitTest: {
      ...blockPopup.splitTest,
      split_test_id: blockPopup.splitTest.id,
      weight: blockPopup.splitTest.distributionPercentage,
    },
  };
};

const handleUnknownError = (error: unknown, consoleMessage: string) => {
  if (error instanceof Error) {
    // eslint-disable-next-line no-console
    console.error(`${consoleMessage}: ${error.message}`);
  } else if (typeof error === 'string') {
    // eslint-disable-next-line no-console
    console.error(`${consoleMessage}: ${error}`);
  } else {
    // eslint-disable-next-line no-console
    console.error(consoleMessage);
  }
};

export const getBlockPopups = async (
  shopId: number,
): Promise<(MayHaveSplitTest & BlockPopup)[]> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/public/block_popups/${shopId}`,
    );
    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }

    const data = await response.json();
    const popups: BlockPopup[] = data.popups.map((p: unknown) =>
      camelCaseKeysDeep(p),
    );

    return popups.map(adaptForV2TargetingLogic);
  } catch (error) {
    captureException(
      new TypeError('Failed to load block popups', { cause: error }),
    );

    return [];
  }
};

export const getCustomFonts = async (shopId: number): Promise<CustomFont[]> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/public/block_popups/custom_fonts/${shopId}`,
    );
    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }

    const data = await response.json();

    return data.custom_fonts.map((p: CustomFont) => camelCaseKeysDeep(p));
  } catch (error) {
    captureException(
      new TypeError('Failed to load custom fonts', { cause: error }),
    );

    return [];
  }
};

type EventData = {
  country: string | undefined;
  event: PopupEventTypes;
  platform: PlatformType;
  popup_id: string;
  shop_id: number;
  split_test_id?: string;
};

export const postPopupEvent = (
  eventType: PopupEventTypes,
  shopId: number,
  popupId: string,
  country: string | null,
  platform: PlatformType,
  splitTestId?: string,
) => {
  const url = `${ANALYTICS_SERVICE_URL}/api/v1/events`;
  const data: EventData = {
    country: country?.toUpperCase(),
    event: eventType,
    platform,
    popup_id: popupId,
    shop_id: shopId,
  };

  if (splitTestId !== undefined) {
    data.split_test_id = splitTestId;
  }

  // Note for debugging, requests sent with sendBeacon do not appear in the fetch/xhr section of network tab
  // They're shown as separate requests with a type of "ping"
  navigator.sendBeacon(url, JSON.stringify(data));
};

type PageEventData = {
  event: PageEventTypes;
  platform: PlatformType;
  shop_id: number;
};

export const postPageEvent = (
  eventType: PageEventTypes,
  platform: PlatformType,
  shopId: number,
) => {
  const url = `${ANALYTICS_SERVICE_URL}/api/v1/page_events`;
  const data: PageEventData = {
    event: eventType,
    platform,
    shop_id: shopId,
  };

  navigator.sendBeacon(url, JSON.stringify(data));
};

interface SusbcriberSearchParameters {
  phone?: string;
  email?: string;
}

interface SubscriberMatchResult {
  matchToken?: string;
  phoneNumberLastFour?: string;
  phoneNumberFirstSix?: string;
}

export const searchMatchingSubscribers = async (
  optInProps: Omit<BlockPopupOptInProps, 'phoneNumber'> & {
    optInMethod: OptInMethod;
  },
  searchParameters: SusbcriberSearchParameters,
): Promise<SubscriberMatchResult> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/opt_in/network/search`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...searchParameters,
          country: optInProps.country,
          popup_id: optInProps.popupId,
          shop_id: optInProps.shopId,
          source: optInProps.source,
          session_id: optInProps.sessionId,
          source_key: optInProps.sourceKey,
          opt_in_method: optInProps.optInMethod,
        }),
      },
    );

    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }

    const data = await response.json();
    return camelCaseKeys(data);
  } catch (error) {
    captureException(
      new TypeError('Failed to search matching subscribers', { cause: error }),
    );
    return {};
  }
};

export const confirmPrefilledPhoneNumber = async (
  matchToken: string,
): Promise<void> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/opt_in/network/opt_in`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          match_token: matchToken,
        }),
      },
    );

    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }
  } catch (error) {
    captureException(
      new Error('Failed to confirm Flow A prefilled number', { cause: error }),
    );
  }
};

export const validateOTPPsoin = async (
  shopId: number,
  phoneNumber: string | undefined,
  code: string,
  sourceKey?: string,
  matchToken?: string,
  optInMethod?: OptInMethod,
): Promise<VerifyResponse> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_ENTERPRISE_PLAN_URL}/v2/opt_in/network/one_time_passcode/verify`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          shop_id: shopId,
          phone_number: phoneNumber,
          code,
          source_key: sourceKey,
          match_token: matchToken,
          opt_in_method: optInMethod,
        }),
      },
    );

    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    handleUnknownError(error, 'Error validating code');
    return null;
  }
};

export const confirmPrefilledEmail = async (): Promise<void> => {
  try {
    fetch(`${CLOUDFLARE_ENTERPRISE_PLAN_URL}/v2/opt_in/network/confirm_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  } catch (error) {
    handleUnknownError(error, 'Error confirming prefilled email');
  }
};

type AgeVerifyResponse = {
  success: boolean;
  minAge: number;
};

export const verifyAgeGate = async (
  shopId: number,
  date: Date,
): Promise<AgeVerifyResponse | undefined> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/public/block_popups/verify_age`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          shop_id: shopId,
          birthdate: `${date.getUTCFullYear()}-${
            date.getUTCMonth() + 1
          }-${date.getUTCDate()}`,
        }),
      },
    );
    if (!response.ok) {
      captureException(
        new TypeError('Failed to verify age', { cause: await response.json() }),
      );
      return undefined;
    }
    const result = await response.json();

    return camelCaseKeys(result) as AgeVerifyResponse;
  } catch (error) {
    captureException(new TypeError('Failed to verify age', { cause: error }));
  }
};

export const psnOptIn = async ({
  referrer: { shopId, subscriberId },
  popupId,
  source,
  sourceKey,
}: PsnOptInRequest): Promise<Pick<PsnOptInResponse, 'offer'> | null> => {
  if (!subscriberId) throw new Error('subscriberId is undefined');
  try {
    const response = await fetch(
      `${CLOUDFLARE_ENTERPRISE_PLAN_URL}/v2/public/psn/opt_in`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          referrer: {
            shop_id: shopId,
            subscriber_id: subscriberId,
          },
          popup_id: popupId,
          source,
          source_key: sourceKey,
        }),
      },
    );

    if (!response.ok)
      throw new Error(`Could not post to PSN with status: ${response.status}`);

    const data: PsnOptInResponse = await response.json();
    return { offer: data.offer };
  } catch (error) {
    handleUnknownError(error, 'Could not opt subscriber in to PSN');
    return null;
  }
};

/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */

import { render } from 'lit';
import updatePhoneInput from '../../../widget/desktop/components/updatePhoneInput';
import closedBubble from './components/closedBubble';
import desktopModal from './components/desktop/desktopModal';
import mobileModal from './components/mobile/mobileModal';
import {
  getCookie,
  preloadImages,
  loadFont,
  isDesktop,
  setCookie,
  checkCountry,
} from '../../../helpers/utility';
import { getPopupVariables } from '../../../services/popup/popupService';
import {
  SUBSCRIBER_ID_COOKIE_NAME,
  SESSION_COOKIE_NAME,
  PARTIAL_POPUP_SIZE,
  BIS_ROOT_ID,
  BIS_DESKTOP_STYLES_SELECTOR,
  BIS_BASE_STYLES_SELECTOR,
  BIS_MOBILE_STYLES_SELECTOR,
  BIS_PHONE_INPUT_SELECTOR,
  SUCCESS_SCREEN,
  BIS_DEFAULT_POPUP_HEADLINE,
  BIS_DEFAULT_SUBSCRIBE_BUTTON_TEXT,
  BUNDLED_STYLES_SELECTOR,
  BIS_FADE_IN_CLASS,
  BIS_FADE_OUT_CLASS,
  BIS_TARGET_ID,
  BIS_ADDED_CLASS,
} from '../../../helpers/constants';
import desktopStyles from './components/desktop/desktopStyles';
import mobileStyles from './components/mobile/mobileStyles';
import baseStyles from './components/baseStyles';
import {
  postBackInStockSubscribe,
  createBackInStockVariantCookie,
  removeNode,
  generateStyleElement,
  getVariantCookieName,
  forceIframeClose,
  getAddToCartElement,
  checkNoNameAttributeButton,
  fetchSettings,
  resolveSettings,
} from '../utils';
import {
  fadeOut,
  focusableElementsSelector,
  removeElementFocusTrap,
  trapFocusInElement,
} from '../../../helpers/ui';
import addToCartLink from './components/addToCartLink';

/**
 * @typedef {Object} Widget
 * @prop {() => void} initWidget
 * @prop {() => void} destroyWidget
 */

/**
 * @typedef {Object & HTMLElement} IntlTelInputHTMLElement
 * @prop {string} selectedCountryData
 */

/**
 * @typedef {Object} PopupPreferences
 * @prop {string=} customCss
 * @prop {string=} logoUrl
 * @prop {string=} buttonText
 * @prop {string=} closeText
 * @prop {string=} popupSize
 * @prop {string=} font
 * @prop {string=} exitButtonColor
 * @prop {string=} buttonTextColor
 * @prop {string=} buttonBackgroundColor
 * @prop {string=} buttonRadius
 * @prop {string=} borderRadius
 * @prop {string=} textColor
 * @prop {string=} backgroundColor
 * @prop {string=} backgroundImage
 * @prop {string=} backgroundStyle
 * @prop {string=} collectionAttributes
 * @prop {string=} delay
 * @prop {string=} headline
 * @prop {string=} style
 * @prop {number=} headlineFontSize
 * @prop {number=} postHeadlineFontSize
 */

/**
 * @typedef {Object} Settings
 * @prop {string} bannerDesktopColor
 * @prop {string} bannerFontSize
 * @prop {string} bannerFontWeight
 * @prop {string} bannerMobileColor
 * @prop {string} bannerTextColor
 * @prop {string} callToActionBannerText
 * @prop {string} callToActionLinkText
 * @prop {string} linkColor
 * @prop {string} linkDecoration
 * @prop {string} linkDecorationColor
 * @prop {string} linkDecorationStyle
 * @prop {string} linkFontSize
 * @prop {string} linkFontWeight
 * @prop {string} linkHoverColor
 * @prop {boolean} forceBanner
 */

/**
 * @typedef {Object} WidgetState
 * @prop {string} sessionId
 * @prop {string} subscriberId
 * @prop {HTMLElement} rootNode
 * @prop {boolean} isDesktop
 * @prop {IntlTelInputHTMLElement | null } phoneInputInstance
 * @prop {string} phoneInputSelector
 * @prop {PopupPreferences | null} popupPreferences
 * @prop {Settings | null} settings
 * @prop {string} currentCountry
 * @prop {string} currentModalScreen
 * @prop {boolean} hasBeenInitBefore
 * @prop {NodeJS.Timeout} successModalTimeout
 */

/**
 * @param {string} shopId
 * @param {string} variantId
 * @returns {Promise<Widget>}
 */
export const createWidget = async (shopId, variantId) => {
  /** @type {WidgetState} */
  const widgetState = {
    sessionId: '',
    subscriberId: '',
    rootNode: document.getElementById(BIS_ROOT_ID),
    isDesktop: true,
    phoneInputInstance: null,
    phoneInputSelector: BIS_PHONE_INPUT_SELECTOR,
    popupPreferences: null,
    settings: null,
    currentCountry: '',
    currentModalScreen: '',
    hasBeenInitBefore: false,
    successModalTimeout: null,
  };

  const destroyWidget = () => {
    removeNode(BIS_ROOT_ID);
    removeNode(BIS_BASE_STYLES_SELECTOR);
    removeNode(BIS_DESKTOP_STYLES_SELECTOR);
    removeNode(BIS_MOBILE_STYLES_SELECTOR);
    removeNode(BUNDLED_STYLES_SELECTOR);
  };

  /** @returns {boolean} */
  const isSuccessScreenActive = () =>
    widgetState.currentModalScreen === SUCCESS_SCREEN;

  const hideWidgetRootNode = () =>
    widgetState.rootNode.style.setProperty('display', 'none');

  const onOptInWidgetFinish = () => {
    if (widgetState.successModalTimeout) {
      clearTimeout(widgetState.successModalTimeout);
      widgetState.successModalTimeout = null;
    }

    hideWidgetRootNode();
    if (!widgetState.isDesktop) {
      removeElementFocusTrap();
    }
    focusFirstElement();
  };

  const onModalExit = () =>
    hideWidget(() => {
      if (!widgetState.isDesktop) {
        removeElementFocusTrap();
      }

      return !isSuccessScreenActive()
        ? renderWidgetEntryPoint()
        : onOptInWidgetFinish();
    });

  const focusFirstElement = () => {
    document
      .querySelector(focusableElementsSelector)
      ?.focus({ preventScroll: true });
    setTimeout(() => document.activeElement?.blur(), 0);
  };

  const onBubbleExit = () => hideWidget();

  /** @param {() => void} callback */
  const hideWidget = (callback = hideWidgetRootNode) =>
    fadeOut(BIS_ROOT_ID, callback, BIS_FADE_IN_CLASS, BIS_FADE_OUT_CLASS);

  const determineDefaultFocusedNode = () => {
    let selector = widgetState.phoneInputSelector;
    if (widgetState.subscriberId) {
      selector = widgetState.isDesktop
        ? 'ps-desktop-widget__submit'
        : 'ps-mobile-widget-overlay__accept-button';
    }

    document.getElementById(selector)?.focus();
  };

  const showModal = () => {
    setWidgetRootNode(true);
    document.body.appendChild(widgetState.rootNode);

    const showSuccess = isSuccessScreenActive();
    const { logoUrl, buttonText, popupSize } = {
      ...widgetState.popupPreferences,
    };
    const pickedPreferencesProps = {
      logoUrl,
      buttonText,
      popupSize,
    };

    let componentToRender = null;
    if (widgetState.isDesktop) {
      componentToRender = desktopModal({
        onExit: onModalExit,
        onSubmit,
        subscriberId: widgetState.subscriberId,
        showSuccess,
        headline: BIS_DEFAULT_POPUP_HEADLINE,
        ...pickedPreferencesProps,
        buttonText: BIS_DEFAULT_SUBSCRIBE_BUTTON_TEXT,
      });
    } else {
      componentToRender = mobileModal({
        handleExit: onModalExit,
        onSubmitOptIn: onSubmit,
        subscriberId: widgetState.subscriberId,
        showSuccess,
        headline: BIS_DEFAULT_POPUP_HEADLINE,
        subscribeText: BIS_DEFAULT_SUBSCRIBE_BUTTON_TEXT,
        ...pickedPreferencesProps,
      });
    }

    render(componentToRender, widgetState.rootNode);

    if (widgetState.isDesktop) {
      widgetState.phoneInputInstance = updatePhoneInput(
        widgetState.currentCountry,
        `#${BIS_PHONE_INPUT_SELECTOR}`,
      );
    } else {
      widgetState.phoneInputSelector =
        widgetState.popupPreferences.popupSize === PARTIAL_POPUP_SIZE
          ? 'ps-mobile-widget-partial__phone-input'
          : 'ps-mobile-widget-overlay__phone-input';
      widgetState.phoneInputInstance = updatePhoneInput(
        widgetState.currentCountry,
        `#${widgetState.phoneInputSelector}`,
      );
      // The success modal is already in focus
      if (!showSuccess) {
        trapFocusInElement();
      }
    }
    determineDefaultFocusedNode();
  };

  const initiateDoubleOptIn = async () => {
    const phone = widgetState.phoneInputInstance?.getNumber();

    createBackInStockVariantCookie(getVariantCookieName(variantId));
    const response = await postBackInStockSubscribe(
      shopId,
      phone,
      '',
      variantId,
    );
    if (response?.subscriber_id) {
      setCookie(SUBSCRIBER_ID_COOKIE_NAME, response?.subscriber_id, 3650);
    }

    widgetState.currentModalScreen = SUCCESS_SCREEN;

    widgetState.successModalTimeout = setTimeout(
      () => hideWidget(onOptInWidgetFinish),
      3000,
    );
    showModal();
  };

  /** @param {Event} event */
  const onSubmit = async (event) => {
    event.preventDefault();

    if (widgetState.subscriberId) {
      createBackInStockVariantCookie(getVariantCookieName(variantId));
      await postBackInStockSubscribe(
        shopId,
        '',
        widgetState.subscriberId,
        variantId,
      );
      hideWidget(onOptInWidgetFinish);
    } else {
      await initiateDoubleOptIn();
    }
  };

  const showAddToCartFormLink = () =>
    render(
      addToCartLink({
        onClick: showModal,
        text: widgetState.settings.callToActionLinkText,
      }),
      widgetState.rootNode,
    );

  const showClosedBubble = () =>
    render(
      closedBubble({
        text: widgetState.settings.callToActionBannerText,
        onOpen: showModal,
        onExit: onBubbleExit,
      }),
      widgetState.rootNode,
    );

  const initPopupPreferences = async () => {
    widgetState.isDesktop = isDesktop();
    widgetState.popupPreferences =
      (await getPopupVariables(
        shopId,
        widgetState.isDesktop ? 'desktop' : 'mobile',
      )) || {};

    const settings = (await fetchSettings(shopId)) || {};
    widgetState.settings = resolveSettings(
      settings,
      widgetState.popupPreferences,
    );

    generateStyleElement(
      baseStyles(widgetState.popupPreferences, widgetState.settings),
      BIS_BASE_STYLES_SELECTOR,
    );

    if (widgetState.isDesktop) {
      const {
        customCss,
        textColor,
        popupSize,
        font,
        backgroundColor,
        backgroundImage,
        backgroundStyle,
        borderRadius,
        buttonRadius,
        buttonBackgroundColor,
        buttonTextColor,
      } = widgetState.popupPreferences;

      generateStyleElement(
        desktopStyles({
          customCss,
          disclaimerTextColor: textColor,
          font,
          backgroundColor,
          backgroundImage,
          backgroundStyle,
          borderRadius,
          buttonRadius,
          buttonBackgroundColor,
          buttonTextColor,
          headlineColor: textColor,
          exitButtonColor: textColor,
          fullscreen: popupSize === 'Fullscreen',
        }),
        BIS_DESKTOP_STYLES_SELECTOR,
      );
    } else {
      generateStyleElement(
        mobileStyles(widgetState.popupPreferences),
        BIS_MOBILE_STYLES_SELECTOR,
      );
      // override PS popup as we only want to show the BIS banner
      forceIframeClose();
    }
  };

  /** @param {boolean} shouldChangeZIndex */
  const setWidgetRootNode = (shouldChangeZIndex = false) => {
    // Remove added class before we remove the node
    widgetState?.rootNode?.parentNode?.classList?.remove(BIS_ADDED_CLASS);

    removeNode(BIS_ROOT_ID);

    const bisRoot = document.createElement('div');
    bisRoot.id = BIS_ROOT_ID;
    bisRoot.classList.add(BIS_FADE_IN_CLASS);
    // Handle z-index conflict with PS popup
    bisRoot.style.zIndex = shouldChangeZIndex ? '2147483647' : '2147483646';
    widgetState.rootNode = bisRoot;
  };

  /**  @returns {Node | null} */
  const findAddToCartFormButtonNode = () => {
    const addToCartScenarios = [
      { selector: 'form[action="/cart/add"] button[name="add"][disabled]' },
      {
        selector:
          'form[action="/cart/add"] button[name="add"][aria-disabled="true"]',
      },
      {
        selector: 'form[action="/cart/add"] button[disabled]',
        callbackLogic: checkNoNameAttributeButton,
      },
    ];

    let addToCartFormButtonNode = null;
    addToCartScenarios.some(({ selector, callbackLogic }) => {
      addToCartFormButtonNode = getAddToCartElement(selector, callbackLogic);
      return addToCartFormButtonNode;
    });

    return addToCartFormButtonNode;
  };

  const renderWidgetEntryPoint = () => {
    setWidgetRootNode();

    // If the client has a custom container for the widget, append it to that container
    // Otherwise, append it to the addToCart button form
    const bisContainer = document.getElementById(BIS_TARGET_ID);
    const addToCartFormButtonNode =
      bisContainer || findAddToCartFormButtonNode();

    if (addToCartFormButtonNode && !widgetState.settings.forceBanner) {
      addToCartFormButtonNode.appendChild(widgetState.rootNode);
      showAddToCartFormLink();

      // Add class to the parent element to allow for custom styling
      if (bisContainer) {
        bisContainer.classList.add(BIS_ADDED_CLASS);
      }
    } else {
      document.body.appendChild(widgetState.rootNode);
      showClosedBubble();
    }

    if (widgetState.hasBeenInitBefore) {
      const findFocusableElement = document.querySelector(
        `#${BIS_ROOT_ID} [role=button], #${BIS_ROOT_ID} button`,
      );
      findFocusableElement?.focus();
    }
  };

  const initWidget = async () => {
    widgetState.sessionId = getCookie(SESSION_COOKIE_NAME);
    widgetState.subscriberId = getCookie(SUBSCRIBER_ID_COOKIE_NAME);

    await initPopupPreferences();
    const { logoUrl, backgroundImage, collectionAttributes } =
      widgetState.popupPreferences;

    widgetState.currentCountry = await checkCountry();

    preloadImages([logoUrl, backgroundImage]);

    loadFont(widgetState.popupPreferences.font);

    const [screen] = collectionAttributes[0];
    widgetState.currentModalScreen = screen;

    renderWidgetEntryPoint();
    widgetState.hasBeenInitBefore = true;
  };

  return {
    initWidget,
    destroyWidget,
  };
};

import { html } from 'lit';
import { handleKeyDownForClickableDivs } from '../../../helpers/ui';

const exitButton = ({ onClick }) => html`
  <div
    id="ps-desktop-widget__close"
    role="button"
    tabindex="0"
    aria-label="Dismiss popup"
    data-cy="popup-exit-button"
    @click=${onClick}
    @keydown=${handleKeyDownForClickableDivs}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path
        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
      />
    </svg>
  </div>
`;

export default exitButton;

import {
  BIS_FADE_IN_CLASS,
  BIS_FADE_OUT_CLASS,
} from '../../../../helpers/constants';

/**
 * @param {import("../widget").PopupPreferences} popupPreferences
 * @param {import("../widget").Settings} settings
 */
const baseStyles = (popupPreferences, settings) => {
  const { font, exitButtonColor, buttonRadius } = popupPreferences;

  const {
    linkColor,
    linkDecoration,
    linkDecorationColor,
    linkDecorationStyle,
    linkFontSize,
    linkFontWeight,
    linkHoverColor,
    bannerDesktopColor,
    bannerTextColor,
  } = settings;

  return `
        #ps-bis-widget-bubble__container,
        #ps-bis-widget-bubble__close-text.bubble__close-text
        {
            font-family: ${font.split(':')[0]}, sans-serif;
        }

        #ps-bis-widget-bubble__close-text > svg {
            fill: ${exitButtonColor};
        }

        #ps-bis-widget-bubble__close-text {
            color: ${bannerTextColor};
            background-color: ${bannerDesktopColor};
            border-radius: ${buttonRadius}px;
            z-index: 2147483647;
            line-height: initial;
            letter-spacing: initial;
        }

        #ps-bis-widget-bubble__container .bubble__close-container {
            border-radius: ${buttonRadius}px;
            text-align: center;
            writing-mode: initial;
            text-orientation: initial;
            cursor: pointer;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
            font-weight: bold;
            z-index: 2147483647;
            bottom: 0px;
            right: 10%;
            position: fixed;
        }

        /* Close Bubble styles */
        #ps-bis-widget-bubble__container .bubble__close-text {
            padding: 12px;
        }

        #ps-bis-widget-bubble__container.bubble__container {
            position: fixed;
            top: initial;
            left: 0px;
            bottom: 0px;
            display: initial;
            height: initial;
            width: 100%;
            align-items: center;
            z-index: 2147483647;
        }

        #ps-bis-widget-bubble__container .bubble_close-widget {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            position: absolute;
            padding: 4px;
            transform: translate(50%, -50%);
            border-radius: 50%;
            fill: #212529;
            text-align: center;
            width: 15px;
            height: 15px;
            background: rgba(255, 255, 255, 0.8);
            z-index: 2147483647;
        }

        .${BIS_FADE_IN_CLASS} {    
            animation: fadeIn ease .3s;
        }
        
        @keyframes fadeIn {
            0% { opacity:0; }
            100% { opacity:1; }
        }
        
        .${BIS_FADE_OUT_CLASS} {
            animation: fadeOut ease .5s;
        }
        
        @keyframes fadeOut {
            0% { opacity:1; }
            100% { opacity:0; }
        }

        #ps-bis-widget-bubble__close-widget {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            position: absolute;
            top: 0px;
            left: 100%;
            padding: 2px;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            fill: #212529;
            text-align: center;
            width: 20px;
            height: 20px;
            background: rgba(255, 255, 255, 0.8);
        }
        
        #ps-bis-widget-bubble__close-widget > svg {
            width: 100%;
            height: 100%;
        }

        #ps-bis-widget__button.ps-bis-button {
            cursor: pointer;
            border: 0px #fff;
            background: transparent;
            background-color: transparent;
            margin: 0px;
            padding: 0px;
            font-family: inherit;
            font-size: ${linkFontSize};
            color: ${linkColor};
            text-decoration: ${linkDecoration};
            text-decoration-style: ${linkDecorationStyle};
            text-decoration-color: ${linkDecorationColor};
            font-weight: ${linkFontWeight};
        }

        #ps-bis-widget__button.ps-bis-button:hover {
            color: ${linkHoverColor};
        }
    `;
};

export default baseStyles;

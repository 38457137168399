import { nanoid } from 'nanoid';
import { PS_POPUP_IFRAME_ID } from '../../../helpers/constants';
import { POPUP_SDK_INITIALIZED } from './constants';

const TARGET_ORIGIN = '*';

type Message = {
  id: string;
  content: unknown;
};

export class IframeMessageDispatcher {
  private iframeWindow: Window | null = null;
  private messageQueue: Message[] = [];

  constructor() {
    window.addEventListener('message', this.onPostscriptPopupEvent.bind(this));
  }

  onPostscriptPopupEvent(e: MessageEvent) {
    if (e.data.type === POPUP_SDK_INITIALIZED) {
      this.onSdkInitialized();
    }
  }

  onSdkInitialized() {
    this.iframeWindow = (
      document.getElementById(PS_POPUP_IFRAME_ID) as HTMLIFrameElement
    )?.contentWindow;

    const queueToProcess = [...this.messageQueue];
    this.messageQueue = [];
    queueToProcess.forEach((message) => {
      this.postMessage(message.content);
    });
  }

  postMessage(message: unknown): string {
    const messageId = nanoid();
    if (!this.iframeWindow) {
      this.messageQueue.push({
        id: messageId,
        content: message,
      });
    } else {
      this.iframeWindow.postMessage(message, TARGET_ORIGIN);
    }
    return messageId;
  }

  dequeueMessage(id: string) {
    this.messageQueue = this.messageQueue.filter((m) => m.id !== id);
  }
}

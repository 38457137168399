import { html } from 'lit';

const headlineSection = ({
  screen = 'phone',
  preHeadline,
  headline,
  postHeadline,
  emailPreHeadline,
  emailPostHeadline,
  emailHeadline,
  backInStock = false,
}) => html`
  <section id="desktop-widget__aria-description" class="ps-section">
    <p id="ps-desktop-widget__pre-headline" class="ps-p">
      ${screen === 'phone' ? preHeadline : emailPreHeadline}
    </p>
    ${backInStock
      ? html`<p
          id="ps-desktop-widget__headline"
          class="ps-p"
          style="margin: 16px;"
        >
          ${screen === 'phone' ? headline : emailHeadline}
        </p>`
      : html`<h4 id="ps-desktop-widget__headline" class="ps-heading">
          ${screen === 'phone' ? headline : emailHeadline}
        </h4>`}
    <p id="ps-desktop-widget__post-headline" class="ps-p">
      ${screen === 'phone' ? postHeadline : emailPostHeadline}${screen ===
        'phone' &&
      (postHeadline || emailPostHeadline)
        ? '*'
        : ''}
    </p>
  </section>
`;

export default headlineSection;

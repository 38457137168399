import { PAGE_VIEW_EVENT } from '../../helpers/constants';

import {
  getProduct,
  getSearchParameters,
  PageViewEvent,
  PageTypes,
} from './utils';

// q is the shopify-defined name for the search query parameter
// Some shops (perfect keto) use s as the search query parameter
// https://shopify.dev/docs/themes/navigation-search/search#query-parameters
const SUPPORTED_SEARCH_QUERY_PARAMS = ['q', 's'];

const mapPageDataToSearchEvent = (shopId: string): PageViewEvent => {
  const searchParams = getSearchParameters(window.location.search) || {};

  const modifiedParams = Object.keys(searchParams).reduce((acc, key) => {
    // Our backend supports q as the search query param, so change any supported param to that
    if (SUPPORTED_SEARCH_QUERY_PARAMS.includes(key)) {
      return {
        ...acc,
        ...{ q: searchParams[key] },
      };
    }
    return {
      ...acc,
      ...{ [key]: searchParams[key] },
    };
  }, {});

  return {
    shop_id: shopId,
    url: window.location.href,
    search_params: modifiedParams,
    page_type: PageTypes.Search,
    referrer: document.referrer,
  };
};

const trackPageViewForSearch = (shopId: string) => {
  window.Postscript.event(PAGE_VIEW_EVENT, mapPageDataToSearchEvent(shopId));
};

const idenfitySearchResultsPage = () =>
  SUPPORTED_SEARCH_QUERY_PARAMS.some((queryParam) =>
    new URLSearchParams(window.location.search).get(queryParam),
  );

const shouldTrackSearchPageEvents = () => {
  const isProductPage = !!getProduct()?.id || !!getProduct()?.productId;
  return idenfitySearchResultsPage() && !isProductPage;
};

export const initializeSearchSubmitted = async (shopId: string) => {
  if (shouldTrackSearchPageEvents()) {
    trackPageViewForSearch(shopId);
  }
};

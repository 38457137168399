import { html } from 'lit';
import { removeSpacesAndLineBreaks } from '../../utils';
import { getRotationForSection } from '../../utils/spinToWin';
import { DEFAULT_COLORS, STYLE_ELEMENT_TYPES, STYLE_RULE_NAMES, } from '../../constants';
export const defaultStyles = {
    [STYLE_ELEMENT_TYPES.SPIN_TO_WIN_SECTION_BORDER]: {
        mobile: {
            [STYLE_RULE_NAMES.BORDER_TOP_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_TOP_STYLE]: 'solid',
            [STYLE_RULE_NAMES.BORDER_TOP_WIDTH]: '2px',
            // private
            [STYLE_RULE_NAMES.INSET]: '50% auto auto 50%',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
            [STYLE_RULE_NAMES.TRANSFORM_ORIGIN]: '0 0',
            [STYLE_RULE_NAMES.WIDTH]: '50%',
        },
    },
};
/**
 * The lines of the sections as divs with a top border.
 */
const spinToWinSectionBorders = (psCssClasses, sections) => /* Rotate must come before translate */ html `
  ${sections.map((_, index) => html `
      <div
        class=${psCssClasses}
        style=${removeSpacesAndLineBreaks(`transform: rotate(${getRotationForSection(index, sections)}deg) translate(0, -50%);`)}
      ></div>
    `)}
`;
export default spinToWinSectionBorders;

import { getProduct, getVariantId } from '../subscriber-events/utils';
import {
  fetchProductStock,
  forceIframeClose,
  getVariantCookieName,
  removeBackInStockVariantCookie,
} from './utils';
import { getCookie, extractHostname } from '../../helpers/utility';
import { createWidget } from './widget/widget';

/**
 * @typedef {Object} ProductStock
 * @prop {boolean} stock
 */

let widgetInstance = null;

/**
 * @param {string} shopId
 * @param {() => void} widgetShownCallback
 * @param {() => void=} widgetHiddenCallback
 */
const trackBackInStock = async (
  shopId,
  widgetShownCallback,
  widgetHiddenCallback,
) => {
  const variantId = getVariantId(getProduct(), window.location.search);

  if (variantId) {
    const data = await fetchProductStock(shopId, variantId);
    const variantCookieName = getVariantCookieName(variantId);
    const variantCookie = getCookie(variantCookieName);

    if (data?.stock && variantCookie) {
      removeBackInStockVariantCookie(variantCookieName);
    }

    if (data && data.stock !== null && !data.stock && !variantCookie) {
      widgetInstance = await createWidget(shopId, variantId);
      try {
        widgetInstance.initWidget();
        widgetShownCallback();
        return;
      } catch (err) {
        // swallow init errors, but ideally this should be going to a logging service
        // eslint-disable-next-line no-console
        console.log('Error initializing ps back in stock widget: ', err);
      }
    }
  }

  widgetHiddenCallback?.();
};

/**
 * @param {string} shopId
 */
const listenForVariantChange = (shopId) => {
  let lastUrl = window.location.href;
  let lastVariantId = getVariantId(getProduct(), window.location.search);
  const observer = new MutationObserver(() => {
    const url = window.location.href;
    const variantId = getVariantId(getProduct(), window.location.search);
    if (url !== lastUrl || lastVariantId !== variantId) {
      lastUrl = url;
      lastVariantId = variantId;
      if (widgetInstance) {
        widgetInstance.destroyWidget();
      }
      trackBackInStock(shopId, forceIframeClose);
    }
  });
  observer.observe(document, { subtree: true, childList: true });
};

/**
 * @param {import("../subscriber-events/utils").SdkConfig} config
 * @returns {boolean}
 */
const shouldInitBackInStock = (config) =>
  (!!getProduct()?.id || !!getProduct()?.productId) &&
  config?.data?.back_in_stock_automation_enabled; // eslint-disable-line camelcase

/**
 * @param {string} shopId
 * @param {import("../subscriber-events/utils").SdkConfig} config
 */
export const initializeBackInStock = (shopId, config) =>
  new Promise((resolve) => {
    try {
      if (shouldInitBackInStock(config)) {
        window.parentHostname = extractHostname(window.origin);

        listenForVariantChange(shopId);
        trackBackInStock(
          shopId,
          () => resolve(true),
          () => resolve(false),
        );
      } else {
        resolve(false);
      }
    } catch {
      resolve(false);
    }
  });

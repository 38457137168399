import { html } from 'lit';
import phoneFullscreenOptIn from '../../../../../widget/mobile/components/phoneFullscreenOptIn';
import phonePartialOptIn from '../../../../../widget/mobile/components/phonePartialOptIn';
import {
  PARTIAL_POPUP_SIZE,
  SUCCESS_SCREEN,
  BUNDLED_STYLES_SELECTOR,
  BIS_ROOT_ID,
  BIS_FADE_IN_CLASS,
} from '../../../../../helpers/constants';
import { handlePopupKeyDown } from '../../../../../helpers/ui';
import { addBundledStyles } from '../../../utils';

/**
 * @typedef {object} MobileModalProps
 * @prop {() => void} handleExit
 * @prop {(evt) => void} onSubmitOptIn
 * @prop {string} subscriberId
 * @prop {string} subscribeText
 * @prop {boolean} showSuccess
 */

/**
 * @param {import("../../widget").PopupPreferences & MobileModalProps} props
 */
const mobileModal = (props) => {
  addBundledStyles(BUNDLED_STYLES_SELECTOR);
  const { popupSize, showSuccess } = props;

  const renderFunction =
    popupSize === PARTIAL_POPUP_SIZE ? phonePartialOptIn : phoneFullscreenOptIn;

  return html`
    <div
      id="ps-bis-widget-mobile-modal__container"
      class=${`phone ${showSuccess && SUCCESS_SCREEN} ${BIS_FADE_IN_CLASS}`}
      data-cy="mobile-popup-container"
      @keydown=${handlePopupKeyDown(props.handleExit, BIS_ROOT_ID)}
    >
      ${renderFunction({ ...props, isLastCollectionScreen: true })}
    </div>
  `;
};

export default mobileModal;

import { html } from 'lit';
import { handleKeyDownForClickableDivs } from '../../../helpers/ui';

const exitButton = ({ id, onClick }) => html`
  <div
    id="${id}"
    @click=${onClick}
    @keydown=${handleKeyDownForClickableDivs}
    role="button"
    tabindex="0"
    aria-label="Dismiss popup"
    data-cy="popup-exit-button"
  >
    ×
  </div>
`;

export default exitButton;

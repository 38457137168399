import { ADD_TO_CART_EVENT } from '../../helpers/constants';
import {
  shouldTrackProductPageEvents,
  mapShopifyDataToProductPageEvent,
  getAddToCartForms,
  isCurrentlySelectedProductInStock,
  isCurrentlySelectedProductFondue,
} from './utils';

const captureAddRequestsMadeWithFetch = (onAddToCart: () => void) => {
  const originalFetch = window.fetch;

  if (typeof originalFetch !== 'function') return;

  // eslint-disable-next-line
  function wrappedFetch(this: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = originalFetch.apply(this, arguments); // eslint-disable-line

    // Intentionally not using async/await here. We don't want this check to block any callbacks the shop may have added.
    response.then((res) => {
      try {
        if (res.url.includes(`${window.location.origin}/cart/add`)) {
          onAddToCart();
        }
      } catch {
        // swallow errors to prevent breaking native fetch
      }
      return res;
    });

    return response;
  }

  window.fetch = wrappedFetch;
};

const captureAddRequestsMadeWithXhr = (onAddToCart: () => void) => {
  const OriginalXhr = window.XMLHttpRequest;

  function WrappedXhr() {
    const xhr = new OriginalXhr();
    xhr.addEventListener('load', () => {
      try {
        if (
          xhr.readyState === OriginalXhr.DONE &&
          xhr.status === 200 &&
          xhr.responseURL.includes(`${window.location.origin}/cart/add`)
        ) {
          onAddToCart();
        }
      } catch {
        // swallow errors to prevent breaking native xhr
      }
    });

    return xhr;
  }
  WrappedXhr.DONE = OriginalXhr.DONE;
  WrappedXhr.HEADERS_RECEIVED = OriginalXhr.HEADERS_RECEIVED;
  WrappedXhr.LOADING = OriginalXhr.LOADING;
  WrappedXhr.OPENED = OriginalXhr.OPENED;
  WrappedXhr.UNSENT = OriginalXhr.UNSENT;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.XMLHttpRequest = WrappedXhr;
};

const trackAddToCartClicks = (shopId: string) => {
  let hasTrackedAddToCartClick = false;
  const onAddToCart = () => {
    if (hasTrackedAddToCartClick) {
      return;
    }

    window.Postscript.event(
      ADD_TO_CART_EVENT,
      mapShopifyDataToProductPageEvent(shopId),
    );
    hasTrackedAddToCartClick = true;
  };

  // We capture fetch and xhr requests to accomodate both newer themes and older jquery based themes
  captureAddRequestsMadeWithFetch(onAddToCart);
  // Hack for Qalo, something with the XHR tracking is breaking their add to cart button so for now kill the tracking for them
  if (shopId !== '9837') {
    captureAddRequestsMadeWithXhr(onAddToCart);
  }

  getAddToCartForms().forEach((form) => {
    // Some shops rely on a traditional form POST rather than an ajax call. Listen for submit to capture those.
    form.addEventListener('submit', onAddToCart);
    // If we can't match any of the above, try to find the add to cart button
    form
      .querySelector('button[name="add"]:not(disabled)')
      ?.addEventListener('click', onAddToCart);
  });
};

export const initializeAddToCart = async (shopId: string) => {
  if (
    (await shouldTrackProductPageEvents(true)) &&
    (await isCurrentlySelectedProductInStock(shopId)) &&
    // We don't want to fire add to cart events when Fondue is added to the cart.
    !isCurrentlySelectedProductFondue()
  ) {
    trackAddToCartClicks(shopId);
  }
};

import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BUTTON_STYLE_ELEMENT, BUTTON_TEXT_ELEMENT, DEFAULT_COLORS, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLE, } from '../constants';
import renderEditorNode from '../development/editorNode';
const TEASER_BLOCK_TEXT_ELEMENTS = Object.assign({}, BUTTON_TEXT_ELEMENT);
const TEASER_BLOCK_STYLE_ELEMENTS = Object.assign({}, BUTTON_STYLE_ELEMENT);
const defaultStyles = {
    [TEASER_BLOCK_STYLE_ELEMENTS.BUTTON]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: '400',
            [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
};
const addBlockActions = ({ block, blockActions, environment, popupActions, undeletableIds, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.onClick = () => {
        var _a;
        if (environment === null || environment === void 0 ? void 0 : environment.isDevelopment) {
            (_a = popupActions.selectBlock) === null || _a === void 0 ? void 0 : _a.call(popupActions, block.id);
        }
        else {
            popupActions.handleTeaserClick();
        }
    };
    if (!(undeletableIds === null || undeletableIds === void 0 ? void 0 : undeletableIds.includes(block.id)))
        // eslint-disable-next-line no-param-reassign
        blockActions.deleteBlock = () => { var _a; return (_a = popupActions.deleteBlock) === null || _a === void 0 ? void 0 : _a.call(popupActions, block.id); };
};
function render(renderData) {
    var _a;
    const { block: { id, type }, blockActions, classes, content, environment, } = renderData;
    const teaserId = `teaser-${id}`;
    const { button: buttonClasses, editorNode: editorNodeClasses } = classes;
    const buttonContent = (_a = content[TEASER_BLOCK_TEXT_ELEMENTS.BUTTON]) !== null && _a !== void 0 ? _a : 'Button';
    return html `
    <div @click=${ifDefined(blockActions.selectBlock)}>
      ${environment.isDevelopment
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      <button
        class=${classMap(buttonClasses)}
        data-block-type=${type}
        id=${teaserId}
        @click=${ifDefined(blockActions.onClick)}
      >
        ${buttonContent}
      </button>
    </div>
  `;
}
const teaserBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: TEASER_BLOCK_STYLE_ELEMENTS,
    textElements: TEASER_BLOCK_TEXT_ELEMENTS,
};
export default teaserBlockHelpers;

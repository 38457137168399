import { html } from 'lit';

const phoneButtonBar = ({
  subscribeText,
  handleExit,
  subscriberId,
  closeText,
}) => html`
  <div id="ps-mobile-widget-overlay__buttons--opt-in">
    <button
      type="submit"
      id="ps-mobile-widget-overlay__accept-button"
      data-popup-engagement="true"
    >
      <span id="ps-mobile-widget-overlay__accept-button-text"
        >${subscribeText}</span
      >
    </button>
    <a
      @click=${(e) => {
        e.preventDefault();
        handleExit();
      }}
      role="button"
      id="ps-mobile-widget-overlay__reject-button"
      class="${subscriberId ? 'ps-subscribed' : ''}"
      href="#"
    >
      ${closeText}
    </a>
  </div>
`;

export default phoneButtonBar;

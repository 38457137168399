/* eslint-disable no-prototype-builtins */
import loader, { isKlaviyoCookieScriptLoaded } from './loader';
import {
  POPUP_SUBSCRIBER_TARGETING_FLAG,
  SESSION_COOKIE_NAME,
  SUBSCRIBER_ID_COOKIE_NAME,
} from '../helpers/constants';
import { setSessionCookie } from '../helpers/events';
import { setCookie, getCookie, isDesktop } from '../helpers/utility';
import { configStateStore } from './common/helpers/stateManager';

const VALID_ORIGINS = [
  'https://static.postscript.io',
  'https://static-dev.postscript.io',
  'https://sdk.postscript.io',
  'https://sdk-dev.postscript.io',
  'http://sdk-local.postscript.io:8080',
  'http://localhost:8080',
  'http://local.postscript.io:8080',
];

const initializeWidget = (shopId, flags, isBackInStockShown, shopShop) => {
  const lazyLoadIframe = () => {
    // lazy load loader.js
    // TODO: fix broken reference to local file
    loader(shopId, flags, shopShop);
  };

  try {
    if (!isDesktop() && isBackInStockShown) {
      return;
    }

    let sessionId = getCookie(SESSION_COOKIE_NAME);

    if (!sessionId) {
      sessionId = setSessionCookie();
    }

    setCookie(SESSION_COOKIE_NAME, sessionId, 1);

    window.addEventListener('message', (event) => {
      if (VALID_ORIGINS.includes(event.origin)) {
        if (event.data.hasOwnProperty('SetCookie')) {
          setCookie(
            event.data.SetCookie.name,
            event.data.SetCookie.status,
            event.data.SetCookie.expirationDays,
            event.data.SetCookie.expirationHours,
            event.data.SetCookie.allowSubdomains,
          );
        } else if (
          event.data.hasOwnProperty('CollectedEmailEvent') &&
          isKlaviyoCookieScriptLoaded()
        ) {
          const { email } = event.data.CollectedEmailEvent;

          // This will push the identity event to Klayvio -- we are only interested in collecting the email for now
          // eslint-disable-next-line no-underscore-dangle
          window._learnq.push([
            'identify',
            {
              $email: email,
            },
          ]);
        } else if (event.data.hasOwnProperty('NavigateToUri')) {
          const { href } = event.data.NavigateToUri;
          window.location.href = href;
        }
      }
    });
    const url = new URL(window.location.href);
    let psID = url.searchParams.get('s-id');
    const { flags } = configStateStore.getState();
    const hasSubscriberTargeting = flags?.includes(
      POPUP_SUBSCRIBER_TARGETING_FLAG,
    );

    if (!psID) psID = getCookie(SUBSCRIBER_ID_COOKIE_NAME);
    if (!psID || hasSubscriberTargeting) lazyLoadIframe();
  } catch (e) {
    /* empty */
  }
};

export default initializeWidget;

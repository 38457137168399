import { v4 as uuidv4 } from 'uuid';
import {
  SESSION_SITE_VISIT_COOKIE_NAME,
  SITE_VISIT_COOKIE_NAME,
} from '../../helpers/constants';
import { getCookie, setCookie } from '../../helpers/utility';
import { PAGE_EVENT_TYPES } from '../../widget/common/block-popups/constants';
import {
  PlatformType,
  postPageEvent,
} from '../../widget/common/block-popups/utils/api';

const TEN_YEARS_IN_DAYS = 3650;
const SESSION_TIMEOUT_MS = 30 * 60 * 1000;

interface SessionData {
  sessionId: string;
  startTime: number;
}

export const trackSessionAnalytics = (
  shopId: string,
  platform: PlatformType,
) => {
  try {
    const sessionCookieValue = getCookie(SESSION_SITE_VISIT_COOKIE_NAME);
    const now = Date.now();

    let sessionData: SessionData | null = sessionCookieValue
      ? JSON.parse(sessionCookieValue)
      : null;
    const sessionExpired = !!(
      sessionData && now - sessionData.startTime > SESSION_TIMEOUT_MS
    );

    // If there's no session OR the existing session expired, create a new one.
    if (!sessionData || sessionExpired) {
      sessionData = {
        sessionId: uuidv4(),
        startTime: now,
      };
      postPageEvent(PAGE_EVENT_TYPES.SESSION, platform, Number(shopId));
    }

    setCookie(
      SESSION_SITE_VISIT_COOKIE_NAME,
      JSON.stringify(sessionData),
      0,
      0,
      false,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to track session analytics', error);
  }
};

const trackUniqueVisitorAnalytics = (
  shopId: string,
  platform: PlatformType,
) => {
  try {
    const uniqueSiteVisitorCookie = getCookie(SITE_VISIT_COOKIE_NAME);

    if (!uniqueSiteVisitorCookie) {
      setCookie(SITE_VISIT_COOKIE_NAME, 'true', TEN_YEARS_IN_DAYS);

      postPageEvent(PAGE_EVENT_TYPES.UNIQUE_VISITOR, platform, Number(shopId));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to track unique visitor analytics', error);
  }
};

export const initializePageAnalytics = (
  shopId: string,
  platform: PlatformType,
) => {
  trackSessionAnalytics(shopId, platform);
  trackUniqueVisitorAnalytics(shopId, platform);
};

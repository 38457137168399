import './polyfills';
import initializeSdk from './sdk/postscript-sdk';
import initializeWidget from './widget/postscript-widget';
import addKlaviyoEvent from './sdk/klaviyo-event';
import { getCurrentScript, getUrlParameter } from './helpers/scripts';
import { initializeBackInStock } from './sdk/back-in-stock/back-in-stock';
import { fetchConfig } from './helpers/utils';
import { initializeProductPageView } from './sdk/subscriber-events/product-page-view';
import { initializeAddToCart } from './sdk/subscriber-events/add-to-cart';
import { initializeSearchSubmitted } from './sdk/subscriber-events/search-submitted';
import initializeListrakEvent from './sdk/integrations/listrak/listrakEvent';
import { configStateStore } from './widget/common/helpers/stateManager';
import { getKlaviyoIdentity } from './sdk/identifiers/api';
import { isDesktop } from './helpers/utility';
import { initializePageAnalytics } from './sdk/analytics/page-analytics';

const platform = isDesktop() ? 'DESKTOP' : 'MOBILE';

(async function initialize() {
  try {
    // Load the script and parse out shopid query string param
    const script = getCurrentScript();
    const queryString = `?${script.src.replace(/^[^?]+\??/, '')}`;
    const shopId = getUrlParameter(queryString, 'shopId');

    // only instantiate sdk if shop id doesn't exist and throw error
    if (!shopId) {
      initializeSdk();

      throw new Error('No shopId parsed from query string');
    }

    const config = await fetchConfig(shopId);

    window.addEventListener('ps-sdk-init', () => {
      initializeProductPageView(shopId);
      initializeAddToCart(shopId);
      initializeSearchSubmitted(shopId);
      // Temporarily disabling to limit BFCM traffic
      // identifyAndLogHeadlessShops(shopId, script);
      initializeListrakEvent(shopId, config?.data?.flags ?? []);
      getKlaviyoIdentity(shopId);
      initializePageAnalytics(shopId, platform);
    });

    configStateStore.updateState({
      flags: config?.data?.flags?.toString(),
      shopId,
      shopShop: config?.data?.shop_shop,
      disclaimer: config?.data?.disclaimer,
    });

    initializeSdk(shopId);

    const isBackInStockShown = await initializeBackInStock(shopId, config);
    initializeWidget(
      shopId,
      config?.data?.flags.toString(),
      isBackInStockShown,
      /* eslint-disable-next-line camelcase */
      config?.data?.shop_shop,
    );
    addKlaviyoEvent(shopId);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error instantiating Postscript SDK: ', err);
  }
})();

import { html } from 'lit';
import { BIS_DEFAULT_LINK_TEXT } from '../../../../helpers/constants';

/**
 * @typedef {object} AddToCartLinkProps
 * @prop {() => void} onClick
 * @prop {string=} text
 */

/**
 * @param {AddToCartLinkProps} props
 */
const addToCartLink = ({ text = BIS_DEFAULT_LINK_TEXT, onClick }) => html`
  <button
    id="ps-bis-widget__button"
    data-cy="ps-bis-widget__button"
    @click=${onClick}
    class="ps-bis-button"
    title="${text}"
    aria-label="Back in stock button"
  >
    ${text}
  </button>
`;

export default addToCartLink;

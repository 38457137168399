/* eslint-disable no-prototype-builtins */
import {
  getCookie,
  isDesktop,
  getAllCookiesByPrefix,
} from '../helpers/utility';
import {
  SESSION_COOKIE_NAME,
  PS_POPUP_IFRAME_ID,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  SPLIT_TEST_STATUS_COOKIE_NAME_PREFIX,
  SPLIT_TEST_QUERY_STRING_PARAMETER,
  CASHBACK_CODE_EVENT_NAME,
  POPUP_IMPRESSION_MESSAGE_TYPE,
  POPUP_SUBMIT_MESSAGE_TYPE,
  POPUP_CLOSE_MESSAGE_TYPE,
  SUBSCRIBER_CREATED_MESSAGE_TYPE,
  POPUP_TRIGGER_TYPES,
} from '../helpers/constants';
import {
  GET_PARENT_FOCUSED_ELEMENT_EVENT,
  HIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT,
  RESTORE_PARENT_FOCUSED_ELEMENT_EVENT,
  STORED_PARENT_FOCUSED_ELEMENT_EVENT,
  UNHIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT,
} from '../helpers/iframe';
import { submitFingerprintData } from '../helpers/fingerprint';
import {
  focusableElementsSelector,
  hideElementsBehindPopupFromScreenReader,
  unhideElementsBehindPopupFromScreenReader,
} from '../helpers/ui';
import { postMessageCashbackApplied } from './loaderUtils';
import {
  publishPopupImpressionEvent,
  publishPopupSubmitEvent,
  publishPopupCloseEvent,
  publishSubscriberCreatedEvent,
} from '../sdk/core/popups/events';
import { configStateStore } from './common/helpers/stateManager';

/**
 * This verifies whether or not a shop has loaded an Klayvio identity tracking cookie
 * @param url the base URL of the cookie script
 * @returns {boolean} if the shop has enabled the cookie
 */
export const isKlaviyoCookieScriptLoaded = () =>
  Array.from(document.getElementsByTagName('script')).some(
    ({ src }) =>
      src.includes('https://static.klaviyo.com') &&
      // eslint-disable-next-line no-underscore-dangle
      window._learnq !== 'undefined',
  );

const mapCookieValuesAsString = (cookies, search) => {
  if (search.get('ps_widget_config')) {
    return [];
  }

  return Object.keys(cookies).reduce(
    (agg, item) => `${agg}${item}:${cookies[item]};`,
    '',
  );
};

const load = (shopId, flags, shopShop) => {
  let lastFocusedElement;

  const container = document.createElement('div');
  container.id = 'ps__widget_container';
  container.style =
    'position: fixed; left: 0; bottom: 0; width: 100vw; z-index: 2147483647;';

  const search = new URLSearchParams(window.location.search);
  const sessionId = getCookie(SESSION_COOKIE_NAME);
  const popupStatuses = getAllCookiesByPrefix(POPUP_STATUS_COOKIE_NAME_PREFIX);
  const splitTestStatuses = getAllCookiesByPrefix(
    SPLIT_TEST_STATUS_COOKIE_NAME_PREFIX,
  );
  const popupStatusesAsString = mapCookieValuesAsString(popupStatuses, search);
  const splitTestStatusesAsString = mapCookieValuesAsString(
    splitTestStatuses,
    search,
  );
  const disclaimerText = configStateStore.getState().disclaimer;

  // Create the iframe
  const iframe = document.createElement('iframe');

  // Set the query params
  const queryParams = new URLSearchParams();
  queryParams.set('shopId', shopId);
  queryParams.set('shopShop', shopShop);
  queryParams.set('sessionId', sessionId);
  queryParams.set('status', popupStatusesAsString);
  queryParams.set(SPLIT_TEST_QUERY_STRING_PARAMETER, splitTestStatusesAsString);
  queryParams.set('flags', flags);
  queryParams.set('disclaimer', JSON.stringify(disclaimerText));
  queryParams.set('subscriberId', window.postscript?.getSubscriberId() ?? '');

  const origin = new URL(window.location.href);
  origin.searchParams.delete('ps_widget_config');
  queryParams.set('origin', origin.toString());

  if (search.get('ps_widget_config')) {
    queryParams.set('config', search.get('ps_widget_config'));
  }

  // If subscriberId is present on page load, then we'll submit the fingerprint data
  // Otherwise we only do so on opt-in
  submitFingerprintData(false, shopId);

  const hostname = process.env.HOSTNAME;
  // Build the URL
  // This is the main entry point for the desktop/mobile rendering code
  const url = isDesktop()
    ? `desktop.html?${queryParams.toString()}`
    : `mobile.html?${queryParams.toString()}`;

  iframe.setAttribute('src', `${hostname}/${url}`);
  iframe.setAttribute('data-cy', PS_POPUP_IFRAME_ID);
  iframe.setAttribute('title', 'Marketing Popup');

  iframe.style = `
    border: none;
    position: fixed;
    visibility: visible;
    z-index: 2147483647;
    max-height: 100vh;
    max-width: 100vw;
    transition: none 0s ease 0s;
    background: none transparent;
    opacity: 0;
  `;
  iframe.style.height = 0;
  iframe.style.width = 0;
  iframe.style.top = 0;
  iframe.style.left = 0;
  iframe.style.bottom = 'auto';
  iframe.style.right = 'auto';
  iframe.style.display = 'none';
  iframe.id = PS_POPUP_IFRAME_ID;

  if (!document.getElementById(PS_POPUP_IFRAME_ID)) {
    container.appendChild(iframe);
  }

  const widgetContainer = document.getElementById('ps__widget_container');

  // remove iframe if already on page (avoid issue with duplicate popup)
  if (document.body != null && widgetContainer) {
    widgetContainer.remove();
  }

  document.body.appendChild(container);

  const iOsDevice = () =>
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].some((agent) => navigator.userAgent.includes(agent));

  const originalBodyOverflow = document.body.style.overflow;
  const originalHtmlOverflow = document.documentElement.style.overflow;

  const updateOverflow = (bodyOverflow, htmlOverflow) => {
    document.body.style.overflow = bodyOverflow;

    if (!iOsDevice()) return;

    document.documentElement.style.overflow = htmlOverflow;
  };

  const manageScroll = ({ data }) => {
    const {
      FrameHeight: frameHeight,
      FrameWidth: frameWidth,
      FrameDisplay: frameDisplay,
      shouldStopScroll = false,
    } = data;

    // close or accept event
    if (frameDisplay === 'none')
      return updateOverflow(originalBodyOverflow, originalHtmlOverflow);

    // event that doesn't change size of popup
    if (!frameHeight && !frameWidth) return;

    // full-screen open event
    const popupOpen = frameHeight === '100%' && frameWidth === '100%';

    if (popupOpen && shouldStopScroll)
      return updateOverflow('hidden', 'hidden');

    updateOverflow(originalBodyOverflow, originalHtmlOverflow);
  };

  window.addEventListener('message', (event) => {
    manageScroll(event);

    if (event.data.hasOwnProperty('FrameHeight')) {
      document.getElementById(PS_POPUP_IFRAME_ID).style.height =
        event.data.FrameHeight;
    }
    if (event.data.hasOwnProperty('FrameWidth')) {
      document.getElementById(PS_POPUP_IFRAME_ID).style.width =
        event.data.FrameWidth;
    }
    if (event.data.hasOwnProperty('FrameDisplay')) {
      document.getElementById(PS_POPUP_IFRAME_ID).style.display =
        event.data.FrameDisplay;
    }
    if (event.data.hasOwnProperty('FrameOpacity')) {
      document.getElementById(PS_POPUP_IFRAME_ID).style.opacity =
        event.data.FrameOpacity;
    }
    if (event.data.hasOwnProperty('FramePosition')) {
      document.getElementById(PS_POPUP_IFRAME_ID).style.top =
        event.data.FramePosition.top;
      document.getElementById(PS_POPUP_IFRAME_ID).style.left =
        event.data.FramePosition.left;
      document.getElementById(PS_POPUP_IFRAME_ID).style.bottom =
        event.data.FramePosition.bottom;
      document.getElementById(PS_POPUP_IFRAME_ID).style.right =
        event.data.FramePosition.right;
    }
    if (event.data === GET_PARENT_FOCUSED_ELEMENT_EVENT) {
      lastFocusedElement = document.activeElement;
      document
        .getElementById(PS_POPUP_IFRAME_ID)
        .contentWindow.postMessage(STORED_PARENT_FOCUSED_ELEMENT_EVENT, '*');
    }
    if (event.data === RESTORE_PARENT_FOCUSED_ELEMENT_EVENT) {
      /**
       * In some cases VoiceOver has document.activeElement set to the body instead of the actual focused element.
       * Trying to focus the body will instead keep focus in the (now closed) popup, so our fallback is to focus
       * the first focusable element then blur it. The first element is usually the skip nav so blurring prevents
       * us from showing an element that's supposed to be hidden.
       */
      if (!lastFocusedElement || lastFocusedElement.tagName === 'BODY') {
        document
          .querySelector(focusableElementsSelector)
          .focus({ preventScroll: true });
        setTimeout(() => document.activeElement.blur(), 0);
      } else {
        lastFocusedElement.focus();
      }
    }
    if (event.data === HIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT) {
      hideElementsBehindPopupFromScreenReader();
    }
    if (event.data === UNHIDE_ELEMENTS_BEHIND_POPUP_FROM_SCREENREADER_EVENT) {
      unhideElementsBehindPopupFromScreenReader();
    }

    if (event.data.trigger === POPUP_TRIGGER_TYPES.EXIT) {
      document.body.addEventListener(
        'mouseleave',
        () => {
          window.postscript.popups.open(event.data.id);
        },
        { once: true },
      );
    }

    if (event.data && event.data.type === CASHBACK_CODE_EVENT_NAME) {
      window?.Fondue?.Promotion?.init({
        code: event.data.code,
        disableToast: true,
      });
    }

    if (event.data && event.data.type === POPUP_IMPRESSION_MESSAGE_TYPE) {
      publishPopupImpressionEvent(event.data);
    }

    if (event.data && event.data.type === POPUP_SUBMIT_MESSAGE_TYPE) {
      publishPopupSubmitEvent(event.data);
    }

    if (event.data && event.data.type === POPUP_CLOSE_MESSAGE_TYPE) {
      publishPopupCloseEvent(event.data);
    }

    if (event.data && event.data.type === SUBSCRIBER_CREATED_MESSAGE_TYPE) {
      publishSubscriberCreatedEvent(event.data);
    }
  });

  document.addEventListener(
    'fondue:promotion.applied',
    () => {
      postMessageCashbackApplied(iframe);
    },
    { once: true },
  );
};

export default load;

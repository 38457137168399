import { nanoid } from 'nanoid';
import {
  LISTRAK_INTEGRATION_FLAG,
  SUBSCRIBER_ID_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
  TWOTAP_ID_COOKIE_NAME,
} from '../../../helpers/constants';
import { sendAttributesEvent } from '../../../helpers/events';
import { getCookie } from '../../../helpers/utility';

interface ListrakEvent {
  type: string;
  metaData: {
    $email?: string;
    birthday?: string;
    phone?: string;
  };
}

export const LISTRAK_SMS_EVENT_NAME = 'ListrakSMSForm';
export const LISTRAK_EMAIL_EVENT_NAME = 'ListrakEmailForm';
export const LISTRAK_OPT_IN_SOURCE = 'Listrak Popup';
export const LISTRAK_OPT_IN_EVENT = 'listrak_popup_submit';

const initializeListrakEvent = (shopId: number, flags: string[]) => {
  if (!flags.includes(LISTRAK_INTEGRATION_FLAG)) {
    return;
  }

  let collectedEmail: string | undefined;
  const sessionId = nanoid();
  const userIdentifiers = {
    subscriber_id: getCookie(SUBSCRIBER_ID_COOKIE_NAME),
    token: getCookie(TOKEN_COOKIE_NAME),
    server_id: getCookie(TWOTAP_ID_COOKIE_NAME),
  };

  window.addEventListener(LISTRAK_SMS_EVENT_NAME, ((
    event: CustomEvent<ListrakEvent>,
  ) => {
    if (event.detail.type !== 'submit') {
      return;
    }

    const { metaData } = event.detail;
    const { phone } = metaData;

    if (phone) {
      window.postscript.event(
        LISTRAK_OPT_IN_EVENT,
        {
          accepts_sms: true,
          source: LISTRAK_OPT_IN_SOURCE,
          phone_number: phone,
          // Our /attributes endpoint only collects unique emails and doesn't update the session id if the same email is submitted twice
          // Attaching the email to the opt in endpoint as well ensures we can match the email to the subscriber
          email: collectedEmail,
          session_id: sessionId,
        },
        false,
      );
    }
  }) as EventListener);

  window.addEventListener(LISTRAK_EMAIL_EVENT_NAME, ((
    event: CustomEvent<ListrakEvent>,
  ) => {
    if (event.detail.type !== 'submit') {
      return;
    }

    const { metaData } = event.detail;
    const { $email, birthday } = metaData;

    if ($email || birthday) {
      collectedEmail = $email;
      sendAttributesEvent({
        shop_id: shopId,
        source: LISTRAK_OPT_IN_SOURCE,
        session_id: sessionId,
        email: $email,
        birthday,
        ...userIdentifiers,
      });
    }
  }) as EventListener);
};

export default initializeListrakEvent;

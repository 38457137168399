import { PublicSdk } from '../types';
import {
  ACTIVE_POPUP_BEHAVIORS,
  CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE,
  POPUP_FIND_MESSAGE_TYPE,
  POPUP_FOUND_MESSAGE_TYPE,
} from './constants';
import { IframeMessageDispatcher } from './iframe';
import {
  ActivePopupBehaviors,
  PopupFindOptions,
  PopupOpenOptions,
} from './types';

const DEFAULT_OPTIONS: PopupOpenOptions = {
  activePopupBehavior: ACTIVE_POPUP_BEHAVIORS.ALWAYS_DISMISS,
  respectPopupStatus: true,
};

const isActivePopupBehavior = (
  activePopupBehavior?: ActivePopupBehaviors,
): activePopupBehavior is ActivePopupBehaviors => {
  if (!activePopupBehavior) {
    return false;
  }

  if (!Object.values(ACTIVE_POPUP_BEHAVIORS).includes(activePopupBehavior)) {
    // eslint-disable-next-line no-console
    console.error(
      `Invalid activePopupBehavior ${activePopupBehavior}. Valid values are ${Object.values(
        ACTIVE_POPUP_BEHAVIORS,
      ).join(', ')}. Default behavior will be used instead.`,
    );
    return false;
  }

  return true;
};

const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean';

const FIND_POPUP_TIMEOUT = 1000;

const registerPopupSdk = (postscriptObject: Partial<PublicSdk>): void => {
  const dispatcher = new IframeMessageDispatcher();
  // eslint-disable-next-line no-param-reassign
  postscriptObject.popups = {
    open: (id: number | string, options: Partial<PopupOpenOptions> = {}) => {
      dispatcher.postMessage({
        type: CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE,
        id,
        options: {
          ...DEFAULT_OPTIONS,
          activePopupBehavior: isActivePopupBehavior(
            options.activePopupBehavior,
          )
            ? options.activePopupBehavior
            : DEFAULT_OPTIONS.activePopupBehavior,
          respectPopupStatus: isBoolean(options.respectPopupStatus)
            ? options.respectPopupStatus
            : DEFAULT_OPTIONS.respectPopupStatus,
        },
      });
    },
    find: async (
      options: PopupFindOptions = {},
    ): Promise<string | number | undefined> =>
      new Promise((resolve) => {
        // Send the request to the iframe
        const messageId = dispatcher.postMessage({
          type: POPUP_FIND_MESSAGE_TYPE,
          options,
        });

        let timeoutId: NodeJS.Timeout | undefined;

        const handleResult = ((event: MessageEvent) => {
          if (event.data.type === POPUP_FOUND_MESSAGE_TYPE) {
            // Clear the timeout if it exists
            clearTimeout(timeoutId);
            // Remove the event listener now that we've received the response
            window.removeEventListener('message', handleResult);
            resolve(event.data.popupId);
          }
        }) as EventListener;

        // Register a listener for the response event
        window.addEventListener('message', handleResult);

        timeoutId = setTimeout(() => {
          dispatcher.dequeueMessage(messageId);
          // Register a timeout to prevent the request from hanging indefinitely
          window.removeEventListener('message', handleResult);
          // eslint-disable-next-line no-console
          console.error('Postscript Popup find request timed out.');
          resolve(undefined);
        }, FIND_POPUP_TIMEOUT);
      }),
  };
};

export default registerPopupSdk;
